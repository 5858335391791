import { msgTypes } from "constants/msgTypes";
import { RouteType } from "constants/RouteType";
import { AuthService } from "services/auth/AuthService";



export class UtilService {
    constructor() { }
    
    static sentenceCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() +
                    txt.substr(1).toLowerCase();
            });
    }

    static upperCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.toUpperCase();
            });
    }

    static lowerCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.toLowerCase();
            });
    }

    static displayEventDate = (startDate, endDate) =>{
        const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const startDt = startDate.split("-");
        const endDt = endDate.split("-") ;
        let dateString="";
        if(startDt[1]===endDt[1]){
          dateString = monthName[Number(startDt[1])-1]
        }else if(startDt[1]!==endDt[1]){
          dateString = monthName[Number(startDt[1])-1]+"-"+monthName[Number(endDt[1])-1];
        }
    
        if(startDt[2]===endDt[2]){
          dateString = dateString +" "+startDt[2]+",";
        }else{
        dateString = dateString +" "+startDt[2]+" - "+endDt[2]+",";
        }
    
        if(startDt[0]===endDt[0]){
          dateString = dateString +" "+startDt[0];
        }else{
          dateString = dateString +" "+startDt[0]+"-"+endDt[0];
        }
        return dateString;
    }

    //End:: change Case

    //load country
        static getCountryList = async () => {
            const url = RouteType.GET_COUNTRY;
            const response = AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR);
            return response;
        };

     //load State List
     static getStateList = async (countryId:string) => {
        const url = RouteType.GET_STATE;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(countryId));
        return response;
    };

    //load State List
    static getCityList = async (stateId:string) => {
        const url = RouteType.GET_CITY;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(stateId));
        return response;
    };

     //load service List
     static getCompanyServiceList = async () => {
        const url = RouteType.ADMIN + RouteType.COMPANY_SERVICES;
        const response = AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR);
        return response;
    };

    //load Upcoming Event List
    static getUpcomingEventList = async () => {
        const url = RouteType.EVENT + RouteType.UPCOMING_EVENTS;
        const response = AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR);
        return response;
    };

    //load Past Event List
    static getPreviousEventList = async () => {
        const url = RouteType.EVENT + RouteType.PAST_EVENTS;
        const response = AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR);
        return response;
    };

    
}