import { Link } from 'react-router-dom';
import Header from '../../sharedScreen/Header';
import Footer from 'screens/sharedScreen/Footer';
import './Verticals.scss'
import { constantMessage } from 'constants/constantMessage';
import ApiUrl from 'services/shared/ApiUrl';
const Verticals = () => {

    const getRemainder = (number) => {
        if (number % 2 === 0) {
            return true;
        } else {
            return false;
        }
    }

    const LeftVerticles = ({ verticles, index }) => {
        return (
            <>

                <div className='container vertical-md vertical-md-sec'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='vertical-img-sec'><img src={verticles.IMAGE} /></div>
                        </div>
                        <div className='col-lg-5 text-content p-0'>
                            <h2><span className={`one-wn ${getClassByIndex(index)}`}>1</span><span className='wn'>WN</span>{verticles.TITLE}</h2>
                            <p>{verticles.CONTENT}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const RightVerticles = ({ verticles, index }) => {
        return (
            <>
                <div className='container vertical-md'>
                    <div className='row'>
                        <div className='col-lg-5 text-content p-0'>
                            <h2><span className={`one-wn ${getClassByIndex(index)}`}>1</span><span className='wn'>WN</span>{verticles.TITLE}</h2>
                            <p>{verticles.CONTENT}</p>
                        </div>
                        <div className='col-lg-7 vertical-right'>
                            <div className='vertical-img-sec'><img src={verticles.IMAGE} /></div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    const getClassByIndex = (index) => {
        switch (index) {
          case 1:
            return 'purple';
          case 2:
            return 'pink';
          case 3:
            return 'yellow';
          case 4:
            return 'green';
          case 5:
            return 'red';
          case 6:
            return 'light-green';
          case 7:
            return 'lemon-green';
          default:
            return '';
        }
      };

    return (
        // <div>
        //     {/* <!--Page Header Start--> */}
        //     <section className="page-header member-section">
        //         <div className="page-header__bg vertical-banner">
        //         </div>
        //         <div className="container">
        //             <div className="page-header__inner">
        //                 <h2>Verticals</h2>
        //                 <div className="thm-breadcrumb__box">
        //                     <ul className="thm-breadcrumb list-unstyled">
        //                         {/* <li><a href="index.html">Home</a></li> */}
        //                         <li><Link to="/">Home</Link></li>
        //                         <li><span className="icon-angle-left"></span></li>
        //                         <li>Verticals</li>
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        //     {/* //   <!--Page Header End--> */}


        //     {/* // <!--vertical Start--> */}
        //     <section className="vertical">
        //         <div className="container">
        //             <div className="pr-sx-about-content">
        //                 <div className="row">
        //                     <div className="col-lg-6">
        //                         <div className="pr-sx-about-text-area">
        //                             <div className="vertical-title headline pera-content  wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
        //                                 <span className="vertical-tag position-relative">1WN One World One Network</span>
        //                                 <h2>Discover <span>1WN </span>
        //                                     Network Verticals</h2>
        //                                 <p><span className="vertical-text"> 1WN</span> Network is dedicated to fostering a diverse and skilled community of logistics professionals. Within our collaborative network, freight forwarders deliver exceptional services to shippers and importers, gaining a competitive edge in the dynamic multimedia operator’s market. Our network's foundation lies in upholding high business ethics and nurturing mutual respect among our members.
        //                                 </p>
        //                                 <br />
        //                                 <p className="text-color-content">“At 1WN, our distinct logistics networks embody collaboration, excellence and a commitment to advancing the global freight forwarding industry.”</p>
        //                             </div>
        //                             <div className="vertical-list ul-li-block  wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
        //                                 <ul>
        //                                     <li><i className="fas fa-check-circle"></i>At 1WN, our strength lies in a robust membership base and a solid
        //                                         infrastructure that forms the backbone of our pro-active.</li>
        //                                     <li><i className="fas fa-check-circle"></i>Cooperative,
        //                                         and formidable network of freight forwarders.</li>
        //                                 </ul>
        //                             </div>
        //                             {/* <div className="ft-thx-btn d-flex  text-center wow flipInX" data-wow-delay="200ms" data-wow-duration="1500ms">
        //                                     <a className="d-flex justify-content-center align-items-center" href="#">Explore More</a>
        //                                 </div> */}
        //                         </div>
        //                     </div>
        //                     <div className="col-lg-6">
        //                         <div className="pr-sx-about-exp-wrapper position-relative  wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
        //                             <span className="ft-about-shape1">
        //                                 <img src="images/resources/vertical-shap.png" alt="" />
        //                             </span>
        //                             {/* <span className="ft-about-shape2">
        //                                     <img src="images/resources/ab-sh2.png" alt="" />
        //                                 </span> */}
        //                             <div className="vertical-img-area">
        //                                 <img src="images/resources/vertical.png" alt="" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        //     {/* <!--vertical End--> */}

        //     {/* <!-- Services Section --> */}
        //     <section className="ft1-services-section">
        //         <div className="auto-container">
        //             <div className="">
        //                 <div className="row">
        //                     {/* <!-- Service Block --> */}
        //                     <div className="col-lg-6">
        //                         <div className="ft1-service-block">
        //                             <div className="inner-box">
        //                                 <div className="content">
        //                                     <img src="images/resources/shap-vertical.png" alt="" />
        //                                     <h4 className="vertical-heading">1WN Global</h4>
        //                                     <div className="text">Our network consists of proficient cargo specialists, experts in handling a variety of cargo types while adhering to rigorous industry standards. Our all-encompassing solutions guarantee the safe and smooth transportation of your customers’ cargo from point A to B. At 1WN Global, our mission is to cultivate a sense of community within the Freight Forwarding Industry, enabling you to broaden your business horizons through strategic global partnerships.
        //                                     </div>
        //                                 </div>
        //                                 <div className="side-icon">
        //                                     <img src="images/project/global-circle.png" alt="" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* <!-- Service Block --> */}
        //                     <div className="col-lg-6">
        //                         <div className="ft1-service-block">
        //                             <div className="inner-box">
        //                                 <div className="content">
        //                                     <img src="images/resources/shap-vertical.png" alt="" />
        //                                     <h4 className="vertical-heading">1WN Project </h4>
        //                                     <div className="text">Navigating Global Movements 1WN stands as a premier network in project logistics, facilitating global movements through Air, Sea, and Land. Our independent project logistics forwarders excel in managing intricate maneuvers, including heavy lifts and the precise handling of oversized, out-of-gauge, and weighty cargo.
        //                                     </div>
        //                                 </div>
        //                                 <div className="side-icon">
        //                                     <img src="images/project/project-circle.png" alt="" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     {/* <!-- Service Block --> */}
        //                     <div className="col-lg-6">
        //                         <div className="ft1-service-block">
        //                             <div className="inner-box">
        //                                 <div className="content">
        //                                     <img src="images/resources/shap-vertical.png" alt="" />
        //                                     <h4 className="vertical-heading">1WN Express</h4>
        //                                     <div className="text">Excellence in Time-Critical Logistics At 1WN Express, we lead the way in delivering responsive solutions for time-critical logistics. Our network of experts guarantees round-the-clock services, including Air on Ground, onboard couriers, Next Flight Out solutions, and airport warehousing worldwide. Backed by strong airline partnerships and exclusive memberships at key airports, we ensure the reliable handling of your cargo.
        //                                     </div>
        //                                 </div>
        //                                 <div className="side-icon">
        //                                     <img src="images/project/express-circle.png" alt="" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     {/* <!-- Service Block --> */}
        //                     <div className="col-lg-6">
        //                         <div className="ft1-service-block">
        //                             <div className="inner-box">
        //                                 <div className="content">
        //                                     <img src="images/resources/shap-vertical.png" alt="" />
        //                                     <h4 className="vertical-heading">1WN Dangerous Goods </h4>
        //                                     <div className="text">1WN Dangerous Goods unites seasoned logistics firms specializing in global hazardous cargo transportation. Our network ensures meticulous attention to detail and compliance with evolving regulations.We offer dedicated resources for air and ocean transportation, expert packing solutions compliant with industry standards, and facilitate collaborations among members engaged in significant military contracts and shipments.
        //                                     </div>
        //                                 </div>
        //                                 <div className="side-icon">
        //                                     <img src="images/project/dangours-circle.png" alt="" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     {/* <!-- Service Block --> */}
        //                     <div className="col-lg-6">
        //                         <div className="ft1-service-block">
        //                             <div className="inner-box">
        //                                 <div className="content">
        //                                     <img src="images/resources/shap-vertical.png" alt="" />
        //                                     <h4 className="vertical-heading">1WN Personal </h4>
        //                                     <div className="text">Personal Touch Network empowers freight forwarders specializing in personal baggage and household items. Our network facilitates fully-integrated relocation services, encompassing personal and household effects removals, office, factory, and small to medium business relocations. With a curated selection of members adhering to professional standards, we foster a collaborative culture, driving collective growth and unlocking global opportunities.
        //                                     </div>
        //                                 </div>
        //                                 <div className="side-icon">
        //                                     <img src="images/project/personal-circle.png" alt="" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="col-lg-6">
        //                         <div className="ft1-service-block">
        //                             <div className="inner-box">
        //                                 <div className="content">
        //                                     <img src="images/resources/shap-vertical.png" alt="" />
        //                                     <h4 className="vertical-heading">1WN Temp </h4>
        //                                     <div className="text">Ensuring Product Integrity 1WN excels in the domain of temperature-controlled logistics. Our global network includes experts experienced in transporting pharmaceuticals and perishable goods. Members enjoy advanced equipment and availability of cold storage facilities, guaranteeing the safe and efficient transit of products throughout the supply chain.
        //                                     </div>
        //                                 </div>
        //                                 <div className="side-icon">
        //                                     <img src="images/project/temp-circle.png" alt="" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="row justify-content-center">
        //                         <div className="col-lg-6">
        //                             <div className="ft1-service-block">
        //                                 <div className="inner-box">
        //                                     <div className="content">
        //                                         <img src="images/resources/shap-vertical.png" alt="" />
        //                                         <h4 className="vertical-heading">1WN Nvocc </h4>
        //                                         <div className="text">Ensuring Product Integrity 1WN excels in the domain of temperature-controlled logistics. Our global network includes experts experienced in transporting pharmaceuticals and perishable goods. Members enjoy advanced equipment and availability of cold storage facilities, guaranteeing the safe and efficient transit of products throughout the supply chain.
        //                                         </div>
        //                                     </div>
        //                                     <div className="side-icon">
        //                                         <img src="images/project/nvocc-circle.png" alt="" />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        //     {/* <!-- End Services Section --> */}

        //     {/* <Footer></Footer> */}
        // </div >
        <>


            {/* <section class="page-header vertical-banner member-section member-vertical">
                <div className=''>
                    <img src="/images/banner/vertical-banner.png"></img>
                </div>
                <div class="page-header__inner membership-head grow-one">
                    <h2 >We are here <br /> to Support Your Global Growth!</h2>
                </div>
            </section> */}



            <section class="page-header mebership-center member-section">
                <img src="/images/banner/vertical-banner.png"></img>
                <div class="page-header__bg member-vertical">
                </div><div class="page-header__inner membership-head">
                    <h2 >We are here <br /> to Support Your Global Growth!</h2>
                </div>
            </section>

            <div className="container heading-previous-event"><div className="row"><div className="col-lg-12"><h2 className='section-title__title'>Our Verticals</h2></div></div></div>
            <div className="rounded-div vertical-section">
                {constantMessage.OUR_VERTICLES.map((verticles, index) => {
                    return (
                        <>

                            {getRemainder(index) ? (<LeftVerticles verticles={verticles} index={index + 1} />) : (<RightVerticles verticles={verticles} index={index + 1} />)}
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default Verticals
