import { Link } from 'react-router-dom';
import Header from '../../sharedScreen/Header'
import Footer from 'screens/sharedScreen/Footer';
import './AboutUs.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SocialMediaIcon from 'screens/sharedScreen/SocialMediaIcon';
import { SunilArora } from '../sunil-arora/SunilArora';
import { Mission } from '../mission-vision/Mission';
import { Vision } from '../mission-vision/Vision';
import Testimonial from '../testimonial/Testimonial';

const AboutUs = () => {


    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    return (
        <div>
            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <img src="/images/banner/past-event.png" alt="" />
                <div className="page-header__bg about-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        {/* <h2>About Us</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><a href="index.html">Home</a></li>
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>About Us</li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            <Mission />
            <Vision />

            {/* <section className='vision-mission-section'>
                <main>
                    <div class="section-title-underlay"></div>
                    <section class="landingpage"></section>
                    <section class="wecanhelp"></section>
                    <section class="internet-statement"></section>
                    <section class="projects"></section>
                </main>
            </section> */}

            {/* about one section */}
            {/* <section id="ft-about-2" class="ft-about-section-2">
                <div class="container">
                    <div class="ft-about-content-2">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="ft-about-text-wrapper-2">
                                    <div class="ft-section-title-2 headline pera-content">
                                        <h2><span>1WN - </span>One World One Network
                                        </h2>
                                        <p>Welcome to 1WN - One World One Network , a global network curated to help elevate businesses & forge bonds in a secured environment, of its exclusive members from the logistics and freight forwarding industry. Founded with a vision to transform collaboration, 1WN unites a community of seasoned logistics professionals, cultivating a new era of operational efficiency and unwavering reliability. </p>
                                    </div>
                                    <div class="ft-about-feature-wrapper-2">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="ft-about-feature-list-item d-flex">
                                                    <div
                                                        class="ft-about-feature-icon d-flex justify-content-center">
                                                        <i class="fas fa-star-of-life"></i>
                                                    </div>
                                                    <div class="ft-about-feature-text headline pera-content">
                                                        <h3>Our Mission</h3>
                                                        <p>Committed to maximize your business growth.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="ft-about-feature-list-item d-flex">
                                                    <div
                                                        class="ft-about-feature-icon d-flex justify-content-center">
                                                        <i class="fas fa-snowflake"></i>
                                                    </div>
                                                    <div class="ft-about-feature-text headline pera-content">
                                                        <h3>Our Vision</h3>
                                                        <p>We aspire to create a world where global connectivity fuels sustainable growth, prosperity and harmony. We aim to overcome geographical barriers, stimulate innovation and uplift communities, thereby shaping a brighter future for generations to come.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="ft-about-img-2-wrapper position-relative">
                                    <span class="ft-about-shape1"><img src="assets/img/shape/ab-sh1.png"
                                        alt="" /></span>
                                    <span class="ft-about-shape2"><img src="images/resources/about-company.webp"
                                        alt="" /></span>
                                    <div class="ft-about-img-2">
                                        <img src="assets/img/about/ab2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* about one section */}

            {/* <!--About One Start--> */}
            <SunilArora />
            {/* <!--About One End--> */}

            <Testimonial />



            {/* <!--Testimonial One Start--> */}
            {/* <section className="testimonial-one">
                <div className="container">
                    <div className="testimonial-one__inner">
                        <div className="testimonial-one__img-one">
                            <img src="images/testimonial/testimanoil-img-1.png" alt=""></img>
                        </div>
                        <div className="section-title text-center">
                            <div className="border-center section-title__tagline-box">
                                <span className="section-title__tagline">clients testimonial</span>
                            </div>
                            <h2 className="section-title__title">Member's success story<br></br> in their words</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-7 col-lg-9">
                                <div className="testimonial-one__right">
                                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Nafiz Bhuiyan</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Robert Son</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Harbert Spin</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Mainto Vula</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>

                        </div>

                        <div className="testimonial-one__dot-style">
                            <div className="swiper-dot-style1"></div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--Testimonial One End--> */}



            {/* <!--Counter One Start--> */}
            {/* <section className="counter-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="counter-one__left wow slideInLeft" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div className="counter-one__img">
                                    <img src="images/resources/counter-img-1.png" alt=""></img>
                                    <div className="counter-one__video-link">
                                        <a href="https://www.youtube.com/watch?v=gcg1tUmTnGE" autoplay className="video-popup">
                                            <div className="counter-one__video-icon">
                                                <span className="icon-play"></span>
                                                <i className="ripple"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="counter-one__right wow slideInRight" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">Global Presence</span>
                                    </div>
                                    <h2 className="section-title__title">Simplifying your Connection of <br></br>the challenges</h2>
                                </div>
                                <p className="counter-one__right-text">Network service involves the planning, implementation,
                                    and control of the efficient and effective movement and storage of goods and materials.
                                </p>
                                <h2 className="section-title__title">Office Location</h2>
                                <ul className="counter-one__count-list list-unstyled">
                                    <li>
                                        <div className="counter-one__icon-and-count">
                                            <div className="counter-one__icon">
                                                <span className="icon-schedule"></span>
                                            </div>
                                        </div>
                                        <p className="counter-one__count-text">Radisson Blu Plaza Hotel,
                                            Delhi Airport, New Delhi -110037</p>
                                    </li>
                                    <li>
                                        <div className="counter-one__icon-and-count">
                                            <div className="counter-one__icon">
                                                <span className="icon-schedule"></span>
                                            </div>
                                        </div>
                                        <p className="counter-one__count-text">IFZA Business Park,
                                            Dubai Silicon Oasis, Dubai</p>
                                    </li>
                                    <li>
                                        <div className="counter-one__icon-and-count">
                                            <div className="counter-one__icon">
                                                <span className="icon-schedule"></span>
                                            </div>
                                        </div>
                                        <p className="counter-one__count-text">511, 8W Building, DAFZA Dubai Airport Free Zone,
                                            Dubai Airport, UAE .</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--Counter One End--> */}

            {/* <!--Why Are We Start--> */}
            {/* <div className='our-mission-footer-form'>
                <section className="why-are-we ">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <div class="section-title text-center">
                                    <div class="border-center section-title__tagline-box">
                                        <span class="section-title__tagline">Join 1WN</span>
                                    </div>
                                    <h2 class="section-title__title">This ambitious vision is only achievable by</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="why-are-we__left">
                                    <div className="why-are-we__img">
                                        <img src="images/resources/global.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="why-are-we__right">

                                    <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">This ambitious vision is only achievable by
                                                working </span>
                                        </div>
                                        <h2 className="section-title__title">Join 1WN
                                        </h2>
                                    </div>
                                    <p className="counter-one__right-text">As we continue to design this dynamic network we are delighted to invite you to transcend boundaries with the next-level networking experience. Join us and become an integral part of a community that redefines logistics, fosters collaboration, and shapes the future of global trade. Let’s connect and navigate through the complexities of the logistics industry and together create One World One Network!
                                    </p>
                                    <div className="why-are-we__img-2">
                                        <img src="images/resources/why-we-img-2.png" alt="" />
                                        <div className="why-are-we__year wow fadeInLeft" data-wow-delay="300ms">
                                            <h3>PROFESSIONAL BUSINESS SUPPORT</h3>
                                            <p>Association service involves the planning, implementation, and control of the efficient and effective movement and storage</p>
                                            <SocialMediaIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div > */}
            {/* <!--Why Are We End--> */}



        </div >
    )
}

export default AboutUs;
