import React from 'react'

export const Advisor = () => {
    return (
        <>
            <section className="page-header advisor-banner member-section">
                <img src="/images/banner/board-of-advisors.jpg" alt="" />
                {/* <video src='/images/video/solidarity-circle-fist-bump-and-business-people-t-2023-11-27-04-56-15-utc.mov'></video> */}

                <div className="page-header__bg post-event-gallery-banner">
                </div>
                {/* <div className="container">
                    <div className="page-header__inner">
                        <h2 className="text-dark">Board of Advisers</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                <li><a href="index.html">Home</a></li>
                                <li><Link className="text-dark" to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li className="text-dark">Board of Advisers</li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </section>
            <section className='advisor-section'>
                <div className='global-img-sec'><img src="/images/resources/globe-png.png" alt="" /></div>
                <div className='container'>
                    <div className='row advisor-content'>
                        <div className='col-lg-7 col-md-12'>
                            <h3>Dr. Renu Parmar
                            </h3>
                            <p>Retired officer of the 1981 batch of the Indian Economic Service, with 36 years of experience across key ministries, including NITI Aayog & the Ministry of Civil Aviation also specialized in policy-making, project approvals, and economic analysis.<br />
                                A Senior Advisor, who contributed establishing the National Aviation University and led efforts to improve air cargo logistics, reducing dwell time and boosting digital transactions. A remarkable initiator to establish the National Air Cargo Community System, earning the Golden Chariot Award and the Gallery of Legends Award at the India Cargo Awards 2017.</p>
                        </div>
                        <div className='col-lg-4 col-md-12 offset-lg-1'>
                            <img src="/images/resources/dr-renu-singh-parmar.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='advisor-section'>
                <div className='global-img-sec right'><img src="/images/resources/globe-png.png" alt="" /></div>
                <div className='container'>
                    <div className='row advisor-content'>
                        <div className='col-lg-4 col-md-12'>
                            <img src="/images/resources/kathrin-reuter.jpg" alt="" />
                        </div>
                        <div className='col-lg-7 col-md-12 offset-lg-1'>
                            <h3>Ms. Kathrin Reuter
                            </h3>
                            <p>With over 30 years of experience in the logistics and freight forwarding industry,
                                Ms. Kathrin Reuter is a seasoned leader and the <strong>Managing Director</strong> at <strong>Embassy Freight Services Europe</strong>.
                                Having started her career in 1987, Ms. Reuter has held key roles, including Director of Seafreight and
                                Sales Manager, where she honed her expertise in supply chain optimization and customer relations.<br />
                                Her extensive experience in both operational and sales roles has made her a trusted expert in the logistics
                                field. Through her leadership and strategic vision, Ms. Reuter continues to shape the future of logistics,
                                focusing on innovation, customer satisfaction, and sustainable growth.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
