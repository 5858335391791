export const msgTypes = {
    LOCALHOST: 'localhost',
    APP_SECRET_KEY:
    {
        KEY: "ACC62A67A3D055E6AF68BD9D3ED9A519",
        IV: "608538a015674f17"
    },

    GOOGLE_CAPTCHA_SITE_KEY_LOCAL: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    GOOGLE_CAPTCHA_SITE_KEY_PROD: '6LfIXD4qAAAAAAWNjWp8RG4wSmM1x0mm5i8nAxZ5',
    GOOGLE_CAPTCHA_SECRET_KEY_PRODUCTION: '6LfIXD4qAAAAAMqs2lFdQov6uqsNWWdu45IEdWY6',

    SUCCESS: {
        code: "1000",
        msg: "SUCCESS"
    },


    DOCUMENT_TYPE: {
        JPG: "jpg",
        JPEG: "jpeg",
        PNG: "png",
        PDF: "pdf",
        TXT: "txt",
        XLSX: "xlsx",
        CSV: "csv"
    },

     //Image Format
     ALL_EXTENSIONS: ".jpg,.jpeg,.png,.svg,.webp,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.7z",
     SUPPORTED_IMAGE_FORMAT:["image/jpg","image/jpeg","image/png"],
     SUPPORTED_IMAGE_PDF:["image/jpg","image/jpeg","image/png","application/pdf",],
     SUPPORTED_ALL_FORMAT:["image/jpg","image/jpeg","image/png","application/pdf", "application/msword"],
     SUPPORTED_ONLY_PDF:["application/pdf"],
     IMAGE_EXTENSIONS:["jpg","jpeg","png"],
     IMAGE_FORMAT : {
         JPEG: "image/jpeg",
         JPG: "image/jpg",
         PNG: "image/png",
         SVG: "application/svg",
         WEBP: "application/webp",
         PDF: "application/pdf",
         WORD: "application/doc",
         EXCEL: "application/xlsx",
         CSV: "application/csv",
        
     },
 
     //Image Size 
     FILE_SIZE_100: 100 * 1024,
     FILE_SIZE_500: 500 * 1024,
 
     //Image validation message
     IMAGE_UNSUPPORTED_FORMAT: "Uploaded file format is unsupported",
     FILE_SIZE_MESSAGE_100 : "File size should be less then 100 kb",
     FILE_SIZE_MESSAGE_500 : "File size should be less then 500 kb",



    APPLICATION_JSON: "application/json",
    ACCESS_TOKEN: "accessToken",
    BASIC_ACCESS_TOKEN: "basicAccessToken",
    STORE_INFO: "userName",

    //status
    //SUCCESS : 'SUCCESS',

    //regex related constant
    RESPONSE_CODE_REGEX: new RegExp("^[1]{1}[0-9]{3}$"),
    RESPONSE_CODE_REGEX_THREE_DIGITS: new RegExp("^[0-1]{1}[0-9]{2}$"),

    // Login Page
    LOGIN_SUCCESS: "Login Successful",
    LOGGED_OUT: "Logout Successful",
    SOMETHING_WENT_WRONG: "Something went wrong",

    //Sweet alert 
    success: "success",
    error: "error",
    OK_KEY: 'OK',

    //Generate Form
    EMPTY_STR: '',

    //Curl strings
    HOST_URL: "HostUrl: ",
    METHOD_TYPE: "MethodType: ",
    REQUEST: "request: ",
    HEADERS_MAP: "HeadersMap: ",

    //Begin:: Table ColumnDef and Headers
    COLUMNDEF_APPROVE: 'approve',
    COLUMNDEF_REJECT: 'reject',
    HEADER_APPROVE: 'Approve',
    HEADER_REJECT: 'Reject',
    HEADER_ACTION: 'Action',

    //End:: Table ColumnDef and Headers
    
    
    VIEW: "view",
    EDIT: "edit",

    //Conformation Alert msg
    CONFORMATION_MESSAGE: {
        UPDATE_RECORD:'Update Record',
        DELETE_RECORD: 'Delete Record',
    },

    CONFORMATION_ICON:{
        WARNING: 'warning',
        ERROR: 'error'
    },


    RESPONSIVE :{
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      },

      

}