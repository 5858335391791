import React from 'react'
import ApiUrl from 'services/shared/ApiUrl'

const MobileNavWrapper = () => {
    return (
<div>
    <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        {/* <!-- /.mobile-nav__overlay --> */}
        <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

            <div className="logo-box">
                <a href="/" aria-label="logo image"><img src="images/resources/1wn-logo.png"
                    alt="" /></a>
            </div>
            {/* <!-- /.logo-box --> */}
            <div className="mobile-nav__container "></div>
            {/* <!-- /.mobile-nav__container --> */}

            <ul className="mobile-nav__contact list-unstyled">
                <li>
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:info@oneworldnetwork.co" target="_blank" rel="noopener noreferrer">info@oneworldnetwork.co</a>
                </li>
                <li>
                    <i className="fa fa-phone-alt"></i>
                    <a href="tel:+971 54 522 3903" target="_blank" rel="noopener noreferrer">+971 54 522 3903</a>
                </li>
            </ul>
            {/* <!-- /.mobile-nav__contact --> */}
            <div className="mobile-sidemenu-space social-menu social-- d-flex">
                <ul>
                    <li><a href="https://www.instagram.com/1wn.co/" target="blank"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/1wn-one-world-one-network/" target="blank"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="https://www.facebook.com/1wn.co"><i className="fab fa-facebook" target="blank"></i></a></li>
                    <li><a href="https://www.youtube.com/@1wn_co" target="blank"><i className="fab fa-youtube"></i></a></li>
                </ul>
            </div>
            {/* <!-- /.mobile-nav__top --> */}





            <div class="main-menu__search-nav-sidebar-btn-box">

                <div class="main-menu__btn-box">
                    <div className='mob-one'>
                        <a class="thm-btn main-menu__btn mr-2" href="#/membership-registration">Join Now
                            <span class="omaga"></span>
                        </a>
                    </div>
                    <div>
                        <a class="thm-btn main-menu__btn" href="https://login.oneworldnetwork.net/" target="blank">Login
                            <span class="omaga btn-omaga"><i class="fa fa-user"></i></span>
                        </a>
                    </div>
                </div>
            </div>



        </div>
        {/* <!-- /.mobile-nav__content --> */}
    </div>
    {/* <!-- /.mobile-nav__wrapper --> */}
</div>

   )
}

export default MobileNavWrapper







