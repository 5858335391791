import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Field, useField } from "formik";
import React from "react";
import MuiLabel from "./MuiLabel";



const PhoneInputField = (props) => {
    const { label, name, ...rest } = props;
    const [field, meta, helpers] = useField(props.name);

    return (
        <Field name={name} {...rest}>
            {({ field }) => {
                return (
                    <React.Fragment>
                        <div>
                        <MuiLabel {...props} />
                        <PhoneInput
                           
                            {...props}
                            {...field}
                            name={name}
                            international
                            autocompleteSearch={true}
                            countryCallingCodeEditable={false}
                            value={field.value}
                            defaultCountry="NO"
                            onChange={(value) => {
                                helpers.setValue(value);
                            }}
                        />
                        </div>
                    </React.Fragment>
                );
            }}

        </Field>
    );
};

export default PhoneInputField;