import React from 'react'
import { Link } from 'react-router-dom';

const Feature = () => {
  return (
   <>
     <section id="dolacz" className="section_join">
                   <div className="padding-global">
                       <div className="container">
                           <div className="padding-section-large">
                               <div className="features-wrapper">
                                   <div className="features-left">
                                       <div className="section-title text-center">
                                           <div className="border-center section-title__tagline-box">
                                               <span className="section-title__tagline">Features</span>
                                           </div>
                                           <h2 className="section-title__title mb-3">Powerful Features of <span className="one--">1</span><span className='wn'>WN</span></h2>
                                           <img src="/images/resources/powerful-feature.png" alt="" />
                                       </div>
                                       <div className="faq-one__btn-box contact-btn"><Link className="thm-btn faq-one__btn" to="/membership-benifits">Learn more <span></span></Link></div>
                                   </div>
                                   <div className="features-right">
                                       <div className="features-card first">
                                           <Link to="/membership-benifits">
                                               <div className="services-two__single">
                                                   <div className="services-two__icon">
                                                       <img src="images/shapes/global.png" alt="" />
                                                   </div>
                                                   <h3 className="services-two__title"><a href="#">Exclusive Global Logistics Network</a></h3>
                                                   <p className="services-two__text">Join a premier network connecting freight forwarding professionals and logistics partners worldwide, ensuring maximum opportunities with minimal competition.</p>
                                               </div>
                                           </Link>
                                       </div>
                                       <div className="features-card second" >
                                           <Link to="/membership-benifits">
                                               <div className="services-two__single">
                                                   <div className="services-two__icon">
                                                       <img src="images/shapes/tailored-freight-management-solutions.png" alt="" />
                                                   </div>
                                                   <h3 className="services-two__title"><a href="#">Tailored Freight Management Solutions</a></h3>
                                                   <p className="services-two__text">Access customized logistics services and specialized freight management solutions that streamline operations and enhance efficiency.</p>
                                               </div>
                                           </Link>
                                       </div>
                                       <div className="features-card third">
                                           <Link to="/membership-benifits">
                                               <div className="services-two__single">
                                                   <div className="services-two__icon">
                                                       <img src="images/shapes/advance-freight-quote.png" alt="" />
                                                   </div>
                                                   <h3 className="services-two__title"><a href="#"> Advanced Freight Quotes and Tracking</a></h3>
                                                   <p className="services-two__text">Utilize an innovative platform for instant, accurate freight quotes and real-time shipment tracking, ensuring smooth international logistics operations.</p>
                                               </div>
                                           </Link>
                                       </div>
                                       <div className="features-card fourth">
                                           <Link to="/membership-benifits">
                                               <div className="services-two__single">
                                                   <div className="services-two__icon">
                                                       <img src="images/shapes/financial-protection-&-support.png" alt="" />
                                                   </div>
                                                   <h3 className="services-two__title"><a href="#">Comprehensive Financial Protection & Support</a></h3>
                                                   <p className="services-two__text">Benefit from secure transactions with financial protection and enjoy 24/7 multilingual support for personalized guidance in logistics services.</p>
                                               </div>
                                           </Link>
                                       </div>
                                       <div className="features-card five">
                                           <Link to="/membership-benifits">
                                               <div className="services-two__single">
                                                   <div className="services-two__icon">
                                                       <img src="images/shapes/industry-leading-events.png" alt="" />
                                                   </div>
                                                   <h3 className="services-two__title"><a href="#">Industry-Leading Events & Local Expertise</a>
                                                   </h3>
                                                   <p className="services-two__text">Participate in high-profile conferences and networking events while leveraging global connections combined with local knowledge to drive growth and innovation.</p>
                                               </div>
                                           </Link>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
   </>
  )
}

export default Feature
