import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom';

export const JoinUs = () => {
  return (
    <section class="contact-us text-center position-relative d-block">
    <div class="container py-5 animated visible">
        <h2 class="mb-4 text-white heading--">Join <span className='one--'>1</span>WN</h2>
        <p class="mb-4 text-white para--">Expand Your Reach, Elevate Your Business</p>
        <Link to="/membership-registration"  class="btn-- btn-primaryy ps-4 pe-4 py-2 my-2" >Join us &nbsp;< EastIcon className='icon-style' />
        </Link>
    </div>

    <div>
        <div class="social-menu social-- d-flex justify-content-center">
            <ul>
                <li>
                    <a href="https://www.instagram.com/1wn.co/" target="blank">
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/1wn-one-world-one-network/" target="blank">
                        <i class="fab fa-linkedin-in">
                        </i>
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/1wn.co">
                        <i class="fab fa-facebook" target="blank">
                        </i>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@1wn_co" target="blank">
                        <i class="fab fa-youtube">
                        </i>
                    </a>
                </li>
            </ul>
        </div>
    </div>


</section>
  )
}
