import { Link } from 'react-router-dom';
import React from 'react'
import Feature from '../powerful-feature/Feature';
import { JoinUs } from '../join-us/JoinUs';
import { CountryFlagCenterMembership } from 'screens/sharedScreen/CountryFlagCenterMembership';

const MembershipCenter = () => {
  return (
    <div>
         {/* <!--Page Header Start--> */}
         <section className="page-header mebership-center member-section">
            <img src="/images/banner/membership-center.png"></img>
                <div className="page-header__bg member--">
                </div>
                    <div className="page-header__inner membership-head">
                        <h2><span className='one--'>1</span>WN <br/>Membership Center</h2>
                        {/* <div className="thm-breadcrumb__box"> */}
                            {/* <ul className="thm-breadcrumb list-unstyled">
                                <li><a href="index.html">Home</a></li>
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Membership Benefits</li>
                            </ul> */}
                        {/* </div> */}
                    </div>
                    {/* <div>
                        <button className='thm-btn main-menu__btn member-ship-btn'><Link to={"/membership-registration"}>Membership Registartion</Link> </button>
                    </div> */}
            </section>
            {/* //   <!--Page Header End--> */}




        <Feature />

        <CountryFlagCenterMembership/>

        <JoinUs />
      
    </div>
  )
}

export default MembershipCenter
