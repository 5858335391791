import { Link } from "react-router-dom";
import Header from '../../sharedScreen/Header'
//import "react-multi-carousel/lib/styles.css";
import './ContactUs.scss';
import Footer from 'screens/sharedScreen/Footer';
import ContactEnquiry from "screens/sharedScreen/ContactEnquiry";
import Carousel from 'react-multi-carousel';
import SocialMediaIcon from "screens/sharedScreen/SocialMediaIcon";
import Testimonial from "../testimonial/Testimonial";

const ContactUs = () => {

    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    return (
        <div>

            {/* <section className="page-header member-section">
                <div className="page-header__bg contact-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Contact Us</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">

                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact" id="contact">
                <div className="container">
                    <div className="heading text-center">

                        <p>Embrace an invitation into a community marked by dynamic collaboration, robust efficiency,<br />and a client-centric ethos at One World Network.</p>
                    </div>
                    <div className='row contact-add-section'>
                        <div className='col-md-4 contact-margin'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>Radisson Blu Plaza Hotel,<br />
                                        Delhi Airport, New Delhi -110037</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 contact-margin'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>IFZA Business Park,<br />
                                        Dubai Silicon Oasis, Dubai</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 contact-margin'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>511, 8W Building, DAFZA Dubai Airport Free Zone,<br />
                                        Dubai Airport, UAE .</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row contact-detail-sec">
                        <div className="col-md-5 position-relative">
                            <div className='contact-page'>
                                <div className="title">
                                    <h3>Contact detail</h3>
                                    <p> Becoming a member of One World Network <br /> means joining a community of dynamic, resilient, and results-driven freight forwarders. </p>
                                </div>
                                <div className="content">

                                    <div className="info">
                                        <div className='icon'><i className="fas fa-mobile-alt"></i></div>
                                        <h4 className="d-inline-block">For India and what’s app :
                                            <br />
                                            <span>+91-73037 33554</span></h4>
                                    </div>

                                    <div className="info">
                                        <div className='icon'><i className="fas fa-mobile-alt"></i></div>
                                        <h4 className="d-inline-block">For Dubai :
                                            <br />
                                            <span>+971-54 522 3903</span></h4>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">

                            <form className="form-margin">

                                <ContactEnquiry />
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Testimonial />
            
            <div className='our-mission-footer-form'>
                <section className="why-are-we ">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <div class="section-title text-center">
                                    <div class="border-center section-title__tagline-box">
                                        <span class="section-title__tagline">Join 1WN</span>
                                    </div>
                                    <h2 class="section-title__title">This ambitious vision is only achievable by</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="why-are-we__left">
                                    <div className="why-are-we__img">
                                        <img src="images/resources/global.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="why-are-we__right">


                                    <p className="counter-one__right-text">As we continue to design this dynamic network we are delighted to invite you to transcend boundaries with the next-level networking experience. Join us and become an integral part of a community that redefines logistics, fosters collaboration, and shapes the future of global trade. Let’s connect and navigate through the complexities of the logistics industry and together create One World One Network!
                                    </p>
                                    <div className="why-are-we__img-2">
                                        <img src="images/resources/why-we-img-2.png" alt="" />
                                        <div className="why-are-we__year wow fadeInLeft" data-wow-delay="300ms">
                                            <h3>PROFESSIONAL BUSINESS SUPPORT</h3>
                                            <p>Association service involves the planning, implementation, and control of the efficient and effective movement and storage</p>
                                            <SocialMediaIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> */}

            {/* <section className="page-header contact-banner member-section"> */}
                {/* <div className="conta">
                    <img src="/images/banner/contact-us.png"></img>

                </div> */}
                {/* <div className="page-header__inner grow">
                    <h2 >We are here <br /> to Support Your Global Growth!</h2>
                </div>
            </section> */}


            <section class="page-header mebership-center member-section">
                <img src="/images/banner/contact-us.png"></img>
                <div class="page-header__bg"></div>
                <div class="page-header__inner membership-head contact-global-text">
                    <h2 >We are here <br /> to Support Your Global Growth!</h2>
                </div>
            </section>

            <section className="contact" id="contact">
                <div className="container">
                    <div className="heading text-center">
                        <p>At <span className="one--">1</span><span className="wn">WN</span> - <span className="one--">One</span> <span className="wn">World</span> <span className="one--">One</span> <span className="wn">Network</span>, we believe that strong connections are the foundation of successful business growth. Whether you’re looking to expand your network, explore new markets, or learn more about the exclusive benefits of <span className="one--">1</span><span className="wn">WN</span> membership, we’re here to help. Our dedicated team is available to answer your questions, provide personalized support, and guide you through your journey with <span className="one--">1</span><span className="wn">WN</span>. </p><br />
                        <p className="mt-2">Feel free to reach out to us through any of our regional offices <br />or schedule a visit to one of our key locations:</p>
                    </div>

                    <div className="row address-lists">
                        <div className="col-lg-3 mb-2">
                            <div className="d-flex justify-content-center cont-location">
                                <div className='icon'>
                                    <i className="fas fa-map-marker-alt"></i>
                                </div>
                                <div className="location--">
                                    <p>Dubai, UAE
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-2">
                            <div className="d-flex justify-content-center cont-location">
                                <div className='icon'>
                                    <i className="fas fa-map-marker-alt"></i>
                                </div>
                                <div className="location--">
                                    <p>Delhi, India
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-2">
                            <div className="d-flex justify-content-center cont-location">
                                <div className='icon'>
                                    <i className="fas fa-map-marker-alt"></i>
                                </div>
                                <div className="location--">
                                    <p>Casablanca, Morocco
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-2">
                            <div className="d-flex justify-content-center cont-location">
                                <div className='icon'>
                                    <i className="fas fa-map-marker-alt"></i>
                                </div>
                                <div className="location--">
                                    <p>Bangkok, Thailand
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="contact " id="contact">
                <div className="container">
                    <div className="row contact-detail-sec">
                        <div className="col-lg-5 col-md-12 position-relative">
                            <div className='contact-page'>
                                <div className="title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-- content">
                                    <div className="info d-block">
                                        {/* <div>
                                            <h4>Addresses
                                            </h4>
                                        </div> */}
                                        <div className="d-flex">
                                            <div className='icon'>
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="icon-one">
                                                <a href='https://www.google.com/maps/place/DAFZ+-+Dubai+Airport+Free+Zone/@25.2604508,55.3702747,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f5db3d5235501:0xb8a647c098c4a23e!8m2!3d25.260446!4d55.372855!16s%2Fg%2F11b7hm0xgm?entry=tts&g_ep=EgoyMDI0MDkxNi4wKgBIAVAD'  target='_blank'>#511, 8W Building,
                                                    DAFZ Dubai<br/> Airport, UAE </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <div className='icon'>
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="icon-one">
                                                <a href='https://www.google.com/maps/place/Radisson+Blu+Plaza+Hotel,+Delhi+Airport/@28.543838,77.1175718,17z/data=!4m9!3m8!1s0x390cfeb030000001:0x30ba15bbf06dfb74!5m2!4m1!1i2!8m2!3d28.5438333!4d77.1201521!16s%2Fg%2F11xy8yzzv?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D'  target='_blank'>Radisson Blu Plaza Hotel,
                                                       <br/> Delhi Airport, INDIA</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="info d-block">
                                        {/* <div>
                                            <h4>Email Id
                                            </h4>
                                        </div> */}
                                        <div className="d-flex">
                                            <div className='icon'>
                                                <i className="fas fa-envelope"></i>

                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="icon-one"> 
                                                <a href="mailto:ceo@oneworrldnetwork.co" rel="noopener noreferrer" target="_blank">ceo@oneworrldnetwork.co</a>  
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="info d-block">
                                        {/* <div>
                                            <h4>Phone No
                                            </h4>
                                        </div> */}
                                        <div className="d-flex">
                                            <div className='icon'>
                                                <i className="fas fa-mobile-alt"></i>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="icon-one">
                                                <a href="tel:+91- 73 037 33554" target="_blank" rel="noopener noreferrer">+91-7303733554</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="info d-block">
                                        {/* <div>
                                            <h4 className="mb-0">Social Media
                                            </h4>
                                        </div> */}
                                        <div className="d-flex">
                                            <div class="social-menu social-- d-flex justify-content-center">
                                                <ul className="ul-list">
                                                    <li>
                                                        <a href="https://www.instagram.com/1wn.co/" target="blank">
                                                            <i class="fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.linkedin.com/company/1wn-one-world-one-network/" target="blank"><i class="fab fa-linkedin-in"></i></a></li><li><a href="https://www.facebook.com/1wn.co"><i class="fab fa-facebook" target="blank"></i></a></li><li><a href="https://www.youtube.com/@1wn_co" target="blank"><i class="fab fa-youtube"></i></a></li></ul></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 col-sm-12">
                            {/* <form className="form-margin"> */}
                            <ContactEnquiry />
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section >

            {/* <Testimonial /> */}


            {/* <div className='our-mission-footer-form'>
                <section className="why-are-we ">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <div class="section-title text-center">
                                    <div class="border-center section-title__tagline-box">
                                        <span class="section-title__tagline">Join 1WN</span>
                                    </div>
                                    <h2 class="section-title__title">This ambitious vision is only achievable by</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="why-are-we__left">
                                    <div className="why-are-we__img">
                                        <img src="images/resources/global.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="why-are-we__right">


                                    <p className="counter-one__right-text">As we continue to design this dynamic network we are delighted to invite you to transcend boundaries with the next-level networking experience. Join us and become an integral part of a community that redefines logistics, fosters collaboration, and shapes the future of global trade. Let’s connect and navigate through the complexities of the logistics industry and together create One World One Network!
                                    </p>
                                    <div className="why-are-we__img-2">
                                        <img src="images/resources/why-we-img-2.png" alt="" />
                                        <div className="why-are-we__year wow fadeInLeft" data-wow-delay="300ms">
                                            <h3>PROFESSIONAL BUSINESS SUPPORT</h3>
                                            <p>Association service involves the planning, implementation, and control of the efficient and effective movement and storage</p>
                                            <SocialMediaIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> */}


        </div >

    )
}

export default ContactUs