import React from 'react'
import Carousel from 'react-multi-carousel';
import './Testimonial.scss';


const Testimonial = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 1700, min: 1200 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        }
    };

    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    return (
        <div>

            <div className='testimonial-section'>
                <div className="section-title text-center">
                    <div className="border-center section-title__tagline-box">
                        <span className="section-title__tagline">clients testimonial</span>
                    </div>
                    <h2 className="section-title__title">Member's success story<br></br> in their words</h2>
                </div>
                <div className='container'>
                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                        {/* <div className="swiper-wrapper"> */}
                        <div className="swiper-slide">
                            <div className="slide">
                                <div className="testimonial">
                                    <h3 className="author">Mr. WILLIAM FERNANDES
                                    </h3>
                                    <h5>SEA CENTER SHIPPING SERVICES LLC</h5>
                                    <h6>UAE</h6>
                                    <blockquote>“ 1WN has significantly expanded our network, connecting us with trusted partners across the globe.”</blockquote>
                                    <img src="/images/testimonial/william-logo.png" alt="" className='testimonial-logo' />
                                </div>

                                <div className="slider-img">
                                    <img src="/images/testimonial/william.jpg" alt="Author Image" />
                                </div>

                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="slide">
                                <div className="testimonial">
                                    <h3 className="author">Mr. Edward Tham
                                    </h3>
                                    <h5>Tropolis Logistics Pvt Ltd</h5>
                                    <h6>SINGAPORE</h6>
                                    <blockquote>“ The connections we’ve made here have opened doors to new markets and valuable partnerships. ”</blockquote>
                                    <img src="/images/testimonial/tropolis-logo.jpg" alt="" className='testimonial-logo' />
                                </div>

                                <div className="slider-img">
                                    <img src="/images/testimonial/edward-tham.jpg" alt="Author Image" />
                                </div>


                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="slide">
                            <div className="testimonial">
                                <h3 className="author">Mr. Khurram Zuberi
                                </h3>
                                <h5>Ryan Agencies Pvt Ltd</h5>
                                <h6>Pakistan</h6>
                                    <blockquote>“ If you’re serious about logistics, this network is an essential tool for building connections and expanding your reach.”</blockquote>
                                    <img src="/images/testimonial/logo-khurram.jpg" alt="" className='testimonial-logo' />
                                </div>

                                <div className="slider-img">
                                    <img src="/images/testimonial/mr-khurram-zuberi.jpg" alt="Author Image" />
                                </div>


                            </div>
                            {/* </div> */}
                        </div>
                    </Carousel>
                </div>
            </div>


            {/* <!--Testimonial One Start--> */}
            {/* <section className="testimonial-one">
                
                <div className="container testimonial-banner-block">
                    <div className="testimonial-one__inner">
                        <div className="testimonial-one__img-one">
                            <img src="images/testimonial/testimanoil-img-1.png" alt=""></img>
                        </div>
                        <div className="row">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-7 col-lg-9">
                                <div className="testimonial-one__right">
                                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                                        <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="testimonial-one__single">
                                                <div className="testimonial-one__quote">
                                                    <span className="icon-quote"></span>
                                                </div>
                                                <div className="testimonial-one__ratting">
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star last-star"></span>
                                                    <span className="icon-star"></span>
                                                </div>
                                                <h3 className="testimonial-one__client-name">Mr. WILLIAM FERNANDES</h3>
                                                <p className="testimonial-one__client-sub-title">SEA CENTER SHIPPING SERVICES LLC</p>
                                                <p className="testimonial-one__client-sub-title">UAE</p>
                                                <p className="testimonial-one__text">1WN has significantly expanded our network, connecting us with trusted partners across the globe.</p>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testimonial-one__single">
                                                <div className="testimonial-one__quote">
                                                    <span className="icon-quote"></span>
                                                </div>
                                                <div className="testimonial-one__ratting">
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star last-star"></span>
                                                </div>
                                                <h3 className="testimonial-one__client-name"> Mr. Edward Tham</h3>
                                                <p className="testimonial-one__client-sub-title">Tropolis Logistics Pvt Ltd</p>
                                                <p className="testimonial-one__client-sub-title">SINGAPORE</p>
                                                <p className="testimonial-one__text">The connections we’ve made here have opened doors to new markets and valuable partnerships.</p>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testimonial-one__single">
                                                <div className="testimonial-one__quote">
                                                    <span className="icon-quote"></span>
                                                </div>
                                                <div className="testimonial-one__ratting">
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star"></span>
                                                    <span className="icon-star last-star"></span>
                                                </div>
                                                <h3 className="testimonial-one__client-name">Mr. Khurram Zuberi</h3>
                                                <p className="testimonial-one__client-sub-title"> Ryan Agencies Pvt Ltd</p>
                                                <p className="testimonial-one__client-sub-title">Pakistan</p>
                                                <p className="testimonial-one__text">If you’re serious about logistics, this network is an essential tool for building connections and expanding your reach.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>

                        </div>

                        <div className="testimonial-one__dot-style">
                            <div className="swiper-dot-style1"></div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--Testimonial One End--> */}
            {/* <!--Testimonial One Start--> */}
            {/* <section className="testimonial-one">
                <div className="section-title text-center">
                    <div className="border-center section-title__tagline-box">
                        <span className="section-title__tagline">clients testimonial</span>
                    </div>
                    <h2 className="section-title__title">Member's success story<br></br> in their words</h2>
                </div>
                <div className="container testimonial-banner-block">
                    <div className="testimonial-one__inner">
                        <div className="semi-circle-section d-flex">
                            <div className="semi-circle one"></div>
                            <div className="semi-circle"></div>
                            <div className="circle left"></div>
                            <div className="profile">
                                <img src="/images/shapes/profile-picture-1.jpg" alt="Profile" />
                            </div>
                            <div className="circle right"></div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <div className='test-img one'>
                                    <img src="/images/shapes/1.png" alt="" />
                                </div>
                                <div className='test-img two'>
                                    <div className='img-sec-ro'></div>
                                    <div className='img-sec-po'></div>
                                </div>
                                <div className='test-img'></div>
                                <div className='test-img'></div>
                                <div className='test-img'></div>
                            </div>
                            <div className="col-xl-12">
                                <div className="testimonial-one__right">
                                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Mr. WILLIAM FERNANDES</h3>
                                                    <p className="testimonial-one__client-sub-title">SEA CENTER SHIPPING SERVICES LLC</p>
                                                    <p className="testimonial-one__client-sub-title">UAE</p>
                                                    <p className="testimonial-one__text">1WN has significantly expanded our network, connecting us with trusted partners across the globe.</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name"> Mr. Edward Tham</h3>
                                                    <p className="testimonial-one__client-sub-title">Tropolis Logistics Pvt Ltd</p>
                                                    <p className="testimonial-one__client-sub-title">SINGAPORE</p>
                                                    <p className="testimonial-one__text">The connections we’ve made here have opened doors to new markets and valuable partnerships.</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Mr. Alvaro Barbosa</h3>
                                                    <p className="testimonial-one__client-sub-title">TotalPlan Porto</p>
                                                    <p className="testimonial-one__client-sub-title">Portugal</p>
                                                    <p className="testimonial-one__text">If you’re serious about logistics, this network is an essential tool for building connections and expanding your reach.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>

                        </div>

                        <div className="testimonial-one__dot-style">
                            <div className="swiper-dot-style1"></div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--Testimonial One End--> */}
        </div>
    )
}

export default Testimonial
