export const constantMessage = {
    OUR_VERTICLES:[
        {
            INDEX: 1,
            TITLE: " Global",
            CONTENT: "Connecting global networks, 1WN Global offers seamless, worldwide logistics solutions tailored to dynamic and complex freight needs",
            IMAGE: "images/verticles/global.png"
        },
        {
            INDEX: 2,
            TITLE: " Temp",
            CONTENT: "With 1WN Temp, we ensure optimal temperature-sensitive logistics, delivering reliable and secure transport for perishable goods and pharmaceuticals.",
            IMAGE: "images/verticles/temp.png"
        },
        {
            INDEX: 3,
            TITLE: " NVOCC",
            CONTENT: " As a Non-Vessel Operating Common Carrier (NVOCC), we streamline freight solutions, offering unmatched flexibility in ocean shipping.",
            IMAGE: "images/verticles/nvocc.png"
        },
        {
            INDEX: 4,
            TITLE: " Projects",
            CONTENT: "1WN Project specializes in managing large-scale logistics, delivering comprehensive solutions for complex, high-stakes transport needs.",
            IMAGE: "images/verticles/projects.png"
        },
        {
            INDEX: 5,
            TITLE: " Dangerous Goods",
            CONTENT: "Safety and compliance are our top priorities at 1WN Dangerous Goods, offering expert handling for hazardous materials worldwide.",
            IMAGE: "images/verticles/dangerous-goods.png"
        },
        {
            INDEX: 6,
            TITLE: " Express",
            CONTENT: "1WN Express delivers rapid, reliable, and time-sensitive logistics solutions to keep your business running on schedule.",
            IMAGE: "images/verticles/express.png"
        },
        {
            INDEX: 7,
            TITLE: " Personal",
            CONTENT: "1WN Personal redefines personal logistics, providing bespoke solutions for individuals’ unique shipping needs with trust and ease.",
            IMAGE: "images/verticles/personal.png"
        },
    ]
}