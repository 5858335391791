import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { NavLink, useLocation } from 'react-router-dom';
import ApiUrl from 'services/shared/ApiUrl';
const Header = () => {
    const location = useLocation();
    const [isSticky, setIsSticky] = useState(false);

    // Function to handle scroll event
    const handleScroll = () => {
        if (window.scrollY > 100) { // adjust the value as per your layout
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className={isSticky ? "stricky-fixed" : ""}>
            <div className="page-wrapper ">
                <header className="main-header">
                    <div class="offer">
                            <p>Join us at the 1WN Conference 2025 &nbsp; –<Link className="register--" to="/upcoming-events"> Register now!</Link></p>
                       
                    </div>
                    {/* <div className='header-top-section'>
                        <marquee behavior="alternate" direction="left">Get local advice for your global request. Contact us now.</marquee>
                    </div> */}
                    <nav className="main-menu">
                        <div className="main-menu__wrapper">
                            <div className="container">
                                <div className="main-menu__wrapper-inner">
                                    <div className="main-menu__left">
                                        <div className="main-menu__logo">
                                            <Link to='/'><img src="images/resources/1wn-logo.png" alt=""></img>
                                            </Link>
                                        </div>
                                        <div className="main-menu__main-menu-box">
                                            <a href="#" className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                                            <ul className="main-menu__list">
                                                <li className={location.pathname === "/" ? "active" : ""}>
                                                    <NavLink exact to="/" activeClassName="active-link">Home</NavLink>
                                                </li>
                                                <li className={location.pathname === "/about-us" ? "active dropdown" : "dropdown"}>
                                                    <NavLink exact to="/about-us" activeClassName="active-link">About us</NavLink>
                                                    <ul>
                                                        <li><Link to='/board-of-advisor'>Board of Advisors</Link></li>
                                                        <li><Link to="accrediations">1WN Associations & Accreditations</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <NavLink to="/membership-center" activeClassName="active-link">1WN Membership Center</NavLink>
                                                    <ul>
                                                        <li><Link to="/membership-benifits">Membership Benefits</Link></li>
                                                        <li><Link to="/membership-registration">Membership Registration</Link></li>
                                                        <li><Link to="/verticals">Our Verticals</Link></li>
                                                        {/* <li><Link to="#">Brochure</Link></li> */}
                                                        <li><a href="/images/pdf/brochure.pdf" target='_blank'>Brochure</a></li>
                                                        <li><Link to="/faqs">FAQs</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <NavLink  activeClassName="active-link">1WN Conference</NavLink>
                                                    <ul>
                                                        <li><Link to="/upcoming-events">Upcoming Events</Link></li>
                                                        <li><Link to="/previous-events">Past Events</Link></li>
                                                        <li><Link to="/post-event-gallery">Photo Gallery</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <NavLink activeClassName="active-link">1WN News & Media</NavLink>
                                                    <ul>
                                                        {/* <li><Link to="/blog">Network News</Link></li> */}
                                                        <li><Link to="/news-and-media">1WN Newsletter</Link></li>
                                                    </ul>
                                                </li>
                                                {/* <li className={location.pathname === "/membership-benifits" ? "active" : "dropdown"}>
                                                    <NavLink to="/membership-benifits" activeClassName="active-link">Membership Benefits</NavLink>
                                                    <ul>
                                                        <li className={location.pathname === "/membership-benifits" ? "active" : ""}><Link to="/membership-registration">Membership Registration</Link></li>
                                                    </ul>
                                                </li> */}
                                                {/* <li>
                                                    <NavLink to="/blog" activeClassName="active-link">Blogs</NavLink>
                                                </li> */}
                                                {/* <li>
                                                    <NavLink exact  activeClassName="active-link">Onepay</NavLink>
                                                </li> */}
                                                <li className={location.pathname === "/contact" ? "active" : ""}>
                                                    <NavLink exact to="/contact" activeClassName="active-link">Contact Us</NavLink>
                                                </li>
                                                {/* <li className="dropdown">
                                                    <a href='javascript:void(0)'>Event & Conferences</a>
                                                    <ul>
                                                        <li><Link to="csr">CSR</Link></li>
                                                        <li><NavLink to="/conferences" activeClassName="active-link">1WN Conferences</NavLink></li>
                                                        <li><NavLink to="/post-event-gallery" activeClassName="active-link">Post Event Gallery</NavLink></li>
                                                        <li><Link to="/event-and-conference">Event & Conferemces Registearion</Link></li>
                                                    </ul>
                                                  
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="main-menu__right">
                                        <div className="main-menu__search-nav-sidebar-btn-box">
                                            {/* <div className="main-menu__search-box">
                                                <a href="#" className="main-menu__search search-toggler fas fa-search"></a>
                                            </div> */}
                                            <div className="main-menu__nav-sidebar-icon">
                                            </div>
                                            <div className="main-menu__btn-box">
                                                {/* <a href="#" className="thm-btn main-menu__btn">Join Now<span></span></a> */}
                                                <Link to={"/membership-registration"} className="thm-btn main-menu__btn mr-2">Join Now<span className='omaga'></span></Link>
                                                <Link target='blank' to={ApiUrl.dashboardBaseUrl} className="thm-btn main-menu__btn">Login<span className='omaga btn-omaga'><i class="fa fa-user"></i></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    )
}

export default Header




















