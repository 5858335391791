import { msgTypes } from "constants/msgTypes";
import { EventRegistrationModel } from "model/screensModels/eventRegistration/EventRegistrationModel";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ApiUrl from "services/shared/ApiUrl";
import { UtilService } from "services/shared/UtilService";

const EventGallery = () => {
    const location = useLocation();
    const [galleryImageList, setGalleryImageList] = useState([]);
    const [allEventList, setAllEventList] = useState([]);
    const [event, setEvent] = useState(null)

    useEffect(() => {
        if (location?.state?.event !== undefined) {
            setGalleryImageList(location?.state?.event?.gallery)
            setEvent(location?.state?.event);
            setAllEventList([])
        } else {
            loadPreviousEventList();
            setGalleryImageList([]);
            setEvent(null);
        }
    }, [location.state])


    const loadPreviousEventList = async () => {
        const res = await UtilService.getPreviousEventList();
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            if (res.data !== '') {
                setAllEventList(res.data)
            } else {
                setAllEventList([])
            }
        }
    }

    return (
        <>
            <div className="page-wrapper wrapper-two">
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>

                {/* <!--Page Header Start--> */}
                <section className="page-header photo-gallery-banner member-section">
                    <img src="images/banner/photo-gallery.png"></img>
                    <div className="page-header__bg post-event-gallery-banner">
                    </div>
                    <div className="container">
                        {/* <div className="page-header__inner">
                            <h2 className="text-dark">Past Event Gallery</h2>
                            <div className="thm-breadcrumb__box">
                                <ul className="thm-breadcrumb list-unstyled">
                                  
                                    <li><Link className="text-dark" to="/">Home</Link></li>
                                    <li><span className="icon-angle-left"></span></li>
                                    <li className="text-dark">Past Event Gallery</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </section>
                {/* //   <!--Page Header End--> */}


                {/* Begin::All Event Gallery */}
                {allEventList?.map(ourEvents => {
                    if (ourEvents?.gallery?.length > 0) {
                        return (
                            <div className="section-padding gallery-section" id="gallery">
                                <div className="container">
                                    <div className="text-center">
                                        <h2 className="title">{ourEvents?.eventName} Gallery</h2>
                                        <p>{UtilService.displayEventDate(ourEvents?.eventStartDate, ourEvents?.eventEndDate)}</p>
                                        <p>{ourEvents?.eventVenue}</p>
                                    </div>
                                    <div className="d-flex justify-content-center gallery-work">
                                    {ourEvents?.gallery?.map(image => {
                                        return (
                                            <div className="gallery sets">
                                                <a className="all Bollywood"><img src={ApiUrl.fileBaseUrl + image} /></a>
                                            </div>
                                        )
                                    })}
                                        </div>
                                    <div className="d-flex">
                                        {ourEvents?.gallery?.map(image => {
                                            return (
                                                <div className="gallery sets">
                                                    <a className="all Bollywood"><img src={ApiUrl.fileBaseUrl + image} /></a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
                {/* End::All Event Gallery */}


                {/* Begin::Particular Event Gallery */}
                {galleryImageList.length > 0 ? (
                    <div className="section-padding gallery-section" id="gallery">
                        <div className="container">
                            <div className="text-center">
                                <h2 className="title">{event?.eventName} Gallery</h2>
                                <p>{event?.eventStartDate !== undefined ? UtilService.displayEventDate(event?.eventStartDate, event?.eventEndDate) : ""}</p>
                                <p>{event?.eventVenue}</p>
                            </div>
                            {galleryImageList?.map(image => {
                                return (
                                    <div className="gallery sets">
                                        <a className="all Bollywood"><img src={ApiUrl.fileBaseUrl + image} /></a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        <div className="no-gallery">
                        <img src="/images/resources/no-photo-gallery.png" alt="" />
                    </div>
                    </div>
                )}
                {/* End::Particular Event Gallery */}
            </div>
        </>
    )
}

export default EventGallery
