import React from 'react'
import Carousel from 'react-multi-carousel';

export const CountryFlag = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 1700, min: 1200 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 5
        },
        mobile: {
            breakpoint: { max: 768, min: 575 },
            items: 4
        }
    };

  return (
    <section className="brand-one">
                <div className="container">
                    {/* <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100,
                "slidesPerView": 5,
                "loop": true,
                "navigation": {
                    "nextEl": "#brand-one__swiper-button-next",
                    "prevEl": "#brand-one__swiper-button-prev"
                },
                "autoplay": { "delay": 5000 },
                "breakpoints": {
                    "0": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "375": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "575": {
                        "spaceBetween": 30,
                        "slidesPerView": 2
                    },
                    "767": {
                        "spaceBetween": 50,
                        "slidesPerView": 3
                    },
                    "991": {
                        "spaceBetween": 50,
                        "slidesPerView": 4
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    }
                }}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                            </div>
                        </div> */}
                    <h2 className="brand-one__text count-box section-title__title">
                         <span className='one'>1</span>
                         <span className='one-wn'>WN</span> &nbsp;Welcomes you to our growing<br></br>&nbsp;
                    Global network in 
                    {/* <span className="count-text countries-flag-text" data-stop="50" data-speed="1500">
                        00
                    </span> */}
                    <span className="countries-flag-text">
                        <span> 50+</span> Countries
                    </span></h2>
                    <Carousel arrows={true} responsive={responsive} showDots={false} autoPlay={true} transitionDuration={200} infinite={true}>

                        <div className="swiper-slide">
                            <img src="images/country-flags/afghanistan.png" alt="afghanistan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/belgium.png" alt="belgium"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/uzbekistan.png" alt="uzbekistan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/us.png" alt="united-states-of-america"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/london.png" alt="london"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/uae.png" alt="united-arab-emirates"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/turkish.png" alt="turkish"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/thailand.png" alt="thailand"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/spain.png" alt="spain"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/singapore.png" alt="Singapore"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/portugal.png" alt="portugal"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/poland.png" alt="poland"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/pakistan.png" alt="pakistan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/oman.png" alt="oman"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/kenya.png" alt="kenya"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/japan.png" alt="japan"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/italy.png" alt="italy"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/india.png" alt="india"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/germany.png" alt="germany"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/france.png" alt="france"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/canada.png" alt="canada"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/brazilian.png" alt="brazilian"></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/country-flags/benin.png" alt="benin"></img>
                        </div>
                    </Carousel>
                </div>
            </section>
  )
}
