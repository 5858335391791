import React from 'react'
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';


const DialogBox = ({title, buttonTitle, element, setOnClose}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOnClose(true)
    };

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    return (
        <React.Fragment>
            <Button variant="outlined" className='thm-btn faq-one__btn'  onClick={handleClickOpen}>
            {/* <AddIcon fontSize="small"/> */}
                {buttonTitle}
            </Button>
            <BootstrapDialog
                // onClose={handleClose}
                open={open}
                fullWidth
                maxWidth="lg"
                
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                        
                    })}
                    className='close-iconn'
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {element}
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default DialogBox