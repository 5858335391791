import {
    Grid,
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import FormikControl from 'components/wrapperComponents/FormikControl'
import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from "formik";
import AttendeeSchema from "schemas/eventRegistartion/AttendeeSchema";
import { AttendeeModel } from "model/screensModels/eventRegistration/AttendeeModel";

const AttendeeForm = ({setAttendeeList, attendeeList}) => {
   // const [attendeeList, setAttendeeList] = useState([]);
    const [initialValue, setInitialValue] = useState(new AttendeeModel());

    const addAttendee = (values, setFieldValue, setFieldTouched) => {
        const data = createData(values.name, values.gender, values.position, values.phoneNumber, values.emailId, values.formalPhoto, values.foodType, values.sponsorshipNeeded);
       // values.attendees.push(data)
        setAttendeeList(oldArray => [...oldArray, data])

        setFieldValue('name', '');
        setFieldTouched('name', false);
        setFieldValue('gender', '');
        setFieldTouched('gender', false);
        setFieldValue('position', '');
        setFieldTouched('position', false);
        setFieldValue('phoneNumber', '');
        setFieldTouched('phoneNumber', false);
        setFieldValue('emailId', '');
        setFieldTouched('emailId', false);
        setFieldValue('formalPhoto', '');
        setFieldTouched('formalPhoto', false);
        setFieldValue('foodType', '');
        setFieldTouched('foodType', false);
        setFieldValue('sponsorshipNeeded', '');
        setFieldTouched('sponsorshipNeeded', false);
    }

    function createData(name, gender, position, phoneNumber, emailId, formalPhoto, foodType, sponsorshipNeeded) {
        return { name, gender, position, phoneNumber, emailId, formalPhoto, foodType, sponsorshipNeeded };
    }

    const removeAttendee = (index) => {
        setAttendeeList([
            ...attendeeList.slice(0, index),
            ...attendeeList.slice(index + 1)
        ]);
    }

    return (
        <>
            <Formik
                initialValues={initialValue}
                validationSchema={AttendeeSchema}
                onSubmit={async (values, { resetForm }) => {
                }}
            >
                {({ handleSubmit, isSubmitting, values, isValid, dirty, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>


                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="name"
                                    label="Name"
                                    placeholder="Enter Full Name"
                                    requiredField
                                    disabled={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl className="border-input"
                                    control="radiogroup"
                                    name="gender"
                                    label="Gender"
                                    requiredField
                                    row
                                    disabled={false}
                                    options={[
                                        { key: "Mr.", value: "mr." },
                                        { key: "Mrs.", value: "mrs." },
                                        { key: "Ms.", value: "ms." },
                                    ]}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="position"
                                    label="Position / Designation"
                                    placeholder="Enter Your Position / Designation"
                                    requiredField
                                    disabled={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="phoneInputField"
                                    name="phoneNumber"
                                    label="Contact Number"
                                    placeholder="Enter Your Contact Number"
                                    requiredField
                                    disabled={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="textfield"
                                    name="emailId"
                                    label="Email-Id"
                                    placeholder="Enter Your Email-Id"
                                    requiredField
                                    disabled={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} className="file-input">
                                <FormikControl
                                    control="uploadImage"
                                    label="Representative Photo"
                                    // requiredField
                                    name="formalPhotoFile"
                                    nameUrl="formalPhoto"
                                // disabled={viewMode}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="radiogroup"
                                    name="foodType"
                                    label="Food Type"
                                    requiredField
                                    disabled={false}
                                    row
                                    options={[
                                        { key: "Muslim Halal", value: "muslim" },
                                        { key: "Vegan", value: "vegan" },
                                        { key: "Vegeterian", value: "veg" },
                                        { key: "No Requirement", value: "no" },
                                    ]}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikControl
                                    control="switch"
                                    name="sponsorshipNeeded"
                                    label="Sponsorship Needed"
                                    requiredField
                                    disabled={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="btn btn-submit"
                                   
                                    onClick={() => addAttendee(values, setFieldValue, setFieldTouched)}
                                    disabled={!dirty || !(isValid)}
                                >Add Attendee</Button>
                            </Grid>

                            {attendeeList?.length > 0 ?
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell align="right">Position</TableCell>
                                                        <TableCell align="right">Phone Number</TableCell>
                                                        <TableCell align="right">Email</TableCell>
                                                        <TableCell align="right">Action</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {attendeeList.map((member, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">{member.name}</TableCell>
                                                            <TableCell align="right">{member.position}</TableCell>
                                                            <TableCell align="right">{member.phoneNumber}</TableCell>
                                                            <TableCell align="right">{member.emailId}</TableCell>
                                                            <TableCell align="right"><DeleteIcon onClick={() => { removeAttendee(index) }} color="action" /></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>


                                </>
                                : ""}
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default AttendeeForm
