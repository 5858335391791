import { msgTypes } from 'constants/msgTypes';
import React, { useEffect, useState } from 'react'
import { UtilService } from 'services/shared/UtilService';
import './PreviousEvents.scss'
import EventRegistrationForm from './EventRegistration';
import DialogBox from 'screens/sharedScreen/DialogBox';
import ApiUrl from 'services/shared/ApiUrl';

const UpcomingEvents = () => {
  const [upcomingEventList, setUpcomingEventList] = useState([]);
  const [onClose, setOnClose] = useState(false);

  useEffect(() => {
    loadUpcomingEventList()
  }, [])

  const loadUpcomingEventList = async () => {
    const res = await UtilService.getUpcomingEventList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      if (res.data !== '') {
        setUpcomingEventList(res.data)
        //setUpcomingEventList([])
      } else {
        setUpcomingEventList([])
      }
    }
  }

  const UpcomingEvents = () => {
    return (
      <>
        {upcomingEventList.map((event) => {
          return (
            <div className='row p-5 events-up'>
              <div className='col-lg-6 d-flex justify-content-center'>
                <div className='event-img'>
                  {(event.image !== '' && event.image !== undefined && event.image !== null) ? (
                    <img src={ApiUrl.fileBaseUrl + event.image} alt=""></img>
                  ) : (
                    <img src="/images/events/no_images.png" alt=""></img>
                  )}
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="events-3-holder h-100">
                  <div className="events-3-description-holder ">
                    <h3 >{event.eventName}</h3>
                    <h6 className='p-0 m-0'>Date : &nbsp;&nbsp;{UtilService.displayEventDate(event.eventStartDate, event.eventEndDate)} </h6>
                    <h6 className='p-0 m-0'>Venu : &nbsp;&nbsp;{event.eventVenue}</h6>

                    <div className="faq-one__btn-box contact-btn d-flex">
                      {/* <button type="submit" className="thm-btn faq-one__btn" >Register now<span> */}
                      <DialogBox
                        title="Event Registration"
                        buttonTitle="Register"
                        element={<EventRegistrationForm eventId={event.eventId} setOnClose={setOnClose} />}
                        setOnClose={setOnClose}
                      />
                      {/* </span>
                  </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div >
          )
        })
        }
      </>
    )
  }

  return (
    <>

      {/* <!--Page Header Start--> */}
      <section className="page-header event-banner member-section">
        <img src="/images/banner/upcoming-event.png"></img>
        <div className="page-header__bg ">

        </div>
        <div className="container membership-container">
          <div className="page-header__inner membership-head">
            {/* <h2><span className='one--'>1</span>WN <br/>Membership Center</h2> */}
            {/* <div className="thm-breadcrumb__box"> */}
            {/* <ul className="thm-breadcrumb list-unstyled">
                                <li><a href="index.html">Home</a></li>
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Membership Benefits</li>
                            </ul> */}
            {/* </div> */}
          </div>
          {/* <div>
                        <button className='thm-btn main-menu__btn member-ship-btn'><Link to={"/membership-registration"}>Membership Registartion</Link> </button>
                    </div> */}
        </div>
      </section>
      {/* //   <!--Page Header End--> */}



      <div className='container heading-previous-event'>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='upcoming--'>Upcoming Events</h1>
          </div>
        </div>
      </div>

      <div className='container upcoming-work'>
        {upcomingEventList.length > 0 ? (
          <UpcomingEvents />
        ) : (
          <div className='event-imp d-flex justify-content-center'>
            <img src='/images/events/no-schedule-events.jpg' width={500} height={500}></img>
          </div>
        )}

      </div >
    </>
  )
}

export default UpcomingEvents