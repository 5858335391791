
import { Link } from "react-router-dom"

const NewsAndMedia = () => {
    return (
        <>

            {/* <!--Page Header Start--> */}
            <section className="page-header newsletter-section">
                <img src="/images/banner/newsletter-banner.png" alt="" />
                <div className="page-header__bg news-media-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        {/* <h2>News And Media</h2> */}
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                {/* <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>News And Media</li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* <!--News One Start--> */}
            <section class="blog-one">
                <div class="container">
                    <div class="section-title text-center">
                        <div class="section-title__tagline-box">
                            <span class="section-title__tagline">Latest News</span>
                        </div>
                        {/* <h2 class="section-title__title">Streamlining your supply<br /> chain processes</h2> */}
                    </div>
                    <div class="row">
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 blog-one__single-sec wow fadeInLeft" data-wow-delay="100ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="/images/pdf/event/july.pdf" target="_blank">
                                        <div class="blog-one__img">
                                            <img src="images/events/july.jpg" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>July</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="/images/pdf/event/july.pdf" target="_blank">Redifine Global Networking with 1wn</a></h3>
                                    <p class="blog-one__text">Connect, Collaborate, Conquer </p>
                                    <div class="blog-one__read-more">
                                        <a href="/images/pdf/event/july.pdf" target="_blank">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 blog-one__single-sec wow fadeInUp" data-wow-delay="200ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="/images/pdf/event/aug.pdf" target="_blank">
                                        <div class="blog-one__img">
                                            <img src="images/events/aug.jpg" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>Aug</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="/images/pdf/event/aug.pdf" target="_blank">Inside One World Network: The power of membership</a></h3>
                                    <p class="blog-one__text">One World Network (1WN) is a premier platform dedicated...</p>
                                    <div class="blog-one__read-more">
                                        <a href="/images/pdf/event/aug.pdf" target="_blank">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 blog-one__single-sec wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="/images/pdf/event/sept.pdf" target="_blank">
                                        <div class="blog-one__img">
                                            <img src="images/events/sept.jpg" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>Sept</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="/images/pdf/event/sept.pdf" target="_blank">Connecting the dots how networking shapes the login industry</a></h3>
                                    <p class="blog-one__text">Collaboration is at the heart of  logistics success... </p>
                                    <div class="blog-one__read-more">
                                        <a href="/images/pdf/event/sept.pdf" target="_blank">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 blog-one__single-sec wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single h-auto">
                                <div class="blog-one__img-box">
                                <a href="/images/pdf/event/oct.png" target="_blank">
                                    <div class="blog-one__img oct-img">
                                        <img src="/images/pdf/event/oct.png" alt="" />
                                    </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>Oct</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 blog-one__single-sec wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="/images/pdf/event/nov.pdf" target="_blank">
                                        <div class="blog-one__img">
                                            <img src="images/events/nov.jpg" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>Nov</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="/images/pdf/event/nov.pdf" target="_blank">One  Network Infinite Opportunities </a></h3>
                                    <p class="blog-one__text">Networking is about leveraging collective strengths and opportunities</p>
                                    <div class="blog-one__read-more">
                                        <a href="/images/pdf/event/nov.pdf" target="_blank">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 blog-one__single-sec wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="/images/pdf/event/fellowship-events-1wn.pdf" target="_blank"> 
                                        <div class="blog-one__img">
                                            <img src="images/events/fellowship-events-1wn.jpg" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="/images/pdf/event/fellowship-events-1wn.pdf" target="_blank">1WN’s exclusive cocktail dinner</a></h3>
                                    <p class="blog-one__text">, 2024, 1WN - One World One Network hosted an unforgettable cocktail...
                                         </p>
                                    <div class="blog-one__read-more">
                                        <a href="/images/pdf/event/fellowship-events-1wn.pdf" target="_blank">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                    </div>
                </div>
            </section>
            {/* <!--News One End--> */}

        </>
    )
}
export default NewsAndMedia