import React from 'react'
// import myVideo from "/images/resources/solidarity-circle-fist-bump-and-business-people.mov";

export const Accrediations = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header association-banner member-section">
                {/* <video src='/images/video/association-video.mp4'></video> */}
                <video src="/images/video/association-video.mp4" controls autoPlay loop muted>
                </video>

                <div className="page-header__bg pioneering post-event-gallery-banner">
                </div>
                <div className="page-header__inner">
                    <h2>Pioneering <br />the Future of Global Freight</h2>
                    <div className="thm-breadcrumb__box">
                        <ul className="thm-breadcrumb list-unstyled">
                            {/* <li><a href="index.html">Home</a></li> */}
                            {/* <li><Link className="text-dark" to="/">Home</Link></li> */}
                            {/* <li><span className="icon-angle-left"></span></li> */}
                            {/* <li className="text-dark">Past Event Gallery</li> */}
                        </ul>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}
            <div className="container heading-previous-event"><div className="row"><div className="col-lg-12"><h2 className='section-title__title'>Associations & Accreditations</h2></div></div></div>
            <div className="rounded-div">
                {/* <div className='container'> */}
                <div className='container vertical-md vertical-md-sec'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='vertical-img-sec p-2'><img src="/images/mission-vision/tiaca.png" /></div>
                        </div>
                        <div className='col-lg-5  text-content p-0'>
                            <h2>TIACA</h2>
                            <p>A proud member of The International Air Cargo Association (TIACA), 1WN aligns with global standards in air cargo, fostering innovation and advocacy across the supply chain.</p>
                        </div>
                    </div>
                </div>

                <div className='container vertical-md'>
                    <div className='row'>
                        <div className='col-lg-5 text-content p-0'>
                            <h2>FIATA</h2>
                            <p>As a FIATA member, 1WN joins a worldwide network dedicated to facilitating seamless and efficient freight forwarding services, promoting best practices and industry standards.</p>
                        </div>
                        <div className='col-lg-7 vertical-right'>
                            <div className='vertical-img-sec p-2'><img src="/images/mission-vision/fiata-logo.png" /></div>
                        </div>
                    </div>
                </div>

                <div className='container vertical-md vertical-md-sec'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='vertical-img-sec p-2'><img src="/images/mission-vision/accai.png" /></div>
                        </div>
                        <div className='col-lg-5 text-content p-0'>
                            <h2>ACCAI</h2>
                            <p>Aligned with ACCAI, 1WN is committed to representing and advancing the freight industry’s interests, ensuring safe and reliable logistics solutions across air cargo and supply chains.</p>
                        </div>
                    </div>
                </div>

                <div className='container vertical-md'>
                    <div className='row'>
                        <div className='col-lg-5 text-content p-0'>
                            <h2>EU Chamber</h2>
                            <p>1WN is honored to collaborate with the EU Chamber, driving European logistics standards and partnerships that bring excellence to global supply chains.</p>
                        </div>
                        <div className='col-lg-7 vertical-right'>
                            <div className='vertical-img-sec p-2 py-4'><img src="/images/mission-vision/eu-india-chamber.jpg" /></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
