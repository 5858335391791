
import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

const AttendeeSchema = 
    Yup.object().shape({
        gender: Yup.string().required(Validation.required.message.replaceAll("^","Gender")),
        name: Yup.string().required(Validation.required.message.replaceAll("^","Name"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        position: Yup.string().required(Validation.required.message.replaceAll("^","Designation"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        phoneNumber: Yup.string().required(Validation.required.message.replaceAll("^","Contact Number"))
                          .matches(Validation.mobile.regex , Validation.mobile.message)
                          .min(10,Validation.minlength.message.replaceAll("^",10))
                          .max(15,Validation.maxlength.message.replaceAll("^",15)),
        emailId: Yup.string().required(Validation.required.message.replaceAll("^","Email-Id"))
                          .matches(Validation.email.regex , Validation.email.message),
        foodType: Yup.string().required(Validation.required.message.replaceAll("^","Food Type")),
        sponsorshipNeeded: Yup.boolean(),

        formalPhoto: Yup.string(),//.required(Validation.required.message.replaceAll("^","Photo")),
        //formalPhotoFile: Yup.string().required(Validation.required.message.replaceAll("^","Photo")),
        formalPhotoFile: Yup.mixed().nullable()
                .test(
                    "FILE_FORMAT",
                    msgTypes.IMAGE_UNSUPPORTED_FORMAT,
                    (value) => !value || (value && [msgTypes.IMAGE_FORMAT.JPEG,
                        msgTypes.IMAGE_FORMAT.JPG, msgTypes.IMAGE_FORMAT.PNG, msgTypes.IMAGE_FORMAT.WEBP, msgTypes.IMAGE_FORMAT.SVG
                    ].includes(value?.type))
                )
                .test(
                    "FILE_SIZE",
                    msgTypes.FILE_SIZE_MESSAGE_100,
                    (value) =>!value || (value && value.size <=msgTypes.FILE_SIZE_100)
                )

      });

      


export default AttendeeSchema;