import React from 'react'

export const Mission = () => {
  return (
    <section className='mission-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 mission-content'>
            <div>
              <h2>Our Mission</h2>
              <p>Committed to maximize your business growth</p>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='mission-image'>
              <img src="images/mission-vision/mission.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
