import React from 'react'

export const SunilArora = () => {
  return (
    <div className="arora-padding">
    <section id="ft-team-details" className="ft-team-details-section">
        <div className="container">
            <div className='row'>
                <div className='col-lg-7'>
                    <div className="ft-team-details-text-wrapper headline pera-content">
                        <div className="ft-team-details-text ">
                            {/* <h3>MR. SUNIL ARORA <span className="ceo-color">(CEO)</span> </h3> */}
                            <h2>Mr. Sunil Arora, CEO</h2>
                            <p> Mr. Sunil Arora is a seasoned logistician with 35 years of experience in freight forwarding and customs clearance.
                                Throughout his career, he has held prestigious roles and earned numerous accolades.
                                He collaborated with India's Ministry of Civil Aviation and Ministry of Commerce on key initiatives.
                                He was also Actively involved with CII, FICCI, and PHD Chambers of Commerce.
                                He is also one of the Participant in senior-level discussions with DGCA, BCAS, and the Board of Airline Representatives (BAR). </p>
                            <div className="content">
                                <div className="info">
                                    <div className='info-one'>
                                    <div className="icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    </div>
                                    <div className='w-100 text-md-100'><h4>
                                        <span>+91-98110 15573 / +971-54 522 3903</span>
                                    </h4></div>
                                </div>
                                <div className="info">
                                    <div  className='info-one'>
                                    <div className="icon">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    </div>
                                    <div className='w-100 text-md-100'>
                                    <h4>
                                        <a href="mailto:ceo@oneworrldnetwork.co" rel="noopener noreferrer" target="_blank">ceo@oneworrldnetwork.co</a>  
                                        {/* <a href='#'>ceo@oneworrldnetwork.co</a> */}
                                    </h4>
                                    </div>
                                </div>
                                <div className="info">
                                   <div  className='info-one'>
                                   <div className="icon">
                                    <i class="fab fa-linkedin-in"></i>
                                    </div>
                                   </div>
                                    <div className='w-100 text-md-100'>
                                    <h4>
                                        <a href='https://www.linkedin.com/in/sunil-arora-93841537?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' rel="noopener noreferrer" target="_blank">Click to visit Linkedin profile</a>
                                    </h4>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="container">
                                <div className="row">
                                    <div className="col-xl-4 col-md-6">
                                        <div className="optech-iconbox-wrap2">
                                            <div className="optech-iconbox-icon2">
                                                <img src="assets/images/iconbox/stacker.png" alt=""/>
                                            </div>
                                            <div className="optech-iconbox-data2">
                                                <h5>Leadership Roles</h5>
                                                <ul>
                                                    <li>President of ACAAI & Air Cargo Club of Delhi.</li>
                                                    <li>Senior Advisor to Delhi Custom Broker Association
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="optech-iconbox-wrap2">
                                            <div className="optech-iconbox-icon2">
                                                <img src="assets/images/iconbox/automation.png" alt=""/>
                                            </div>
                                            <div className="optech-iconbox-data2">
                                                <h5>Notable Awards</h5>
                                                <ul>
                                                    <li>Mentor of the Year (2018)</li>
                                                    <li>Logistics Industry Ambassador Award (2023)
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="optech-iconbox-wrap2">
                                            <div className="optech-iconbox-icon2">
                                                <img src="assets/images/iconbox/utilization.png" alt=""/>
                                            </div>
                                            <div className="optech-iconbox-data2">
                                                <h5>Education & Certifications</h5>
                                                <ul>
                                                    <li>MBA from IIMM Advance Cargo Training from IATA 
                                                    </li>
                                                    <li>
                                                    Certified in Dangerous goods Regulations. (CAT3 - DGR )

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className="ft-team-details-img position-relative">
                        <img src="images/resources/sunil-sir.png" alt="" />
                    </div>
                </div>
            </div>
            {/* <div className="ft-team-details-content position-relative">
                <span className="design-shape position-absolute"><img src="images/resources/arora-img-bg.png"
                    alt="" /></span>
                <div className="row">
                    <div className="col-lg-4 team-details">
                        <div className="ft-team-details-img position-relative">
                            <img src="images/resources/sunil-arora.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="ft-team-details-text-wrapper headline pera-content">
                            <div className="ft-team-details-text">
                                <h3>MR. SUNIL ARORA <span className="ceo-color">(CEO)</span> </h3>
                                <p> Mr. Sunil Arora is a seasoned professional,
                                    logistician with 35 years of
                                    comprehensive
                                    expertise in freight forwarding, customs clearance within the Cargo
                                    Industry. He
                                    has held
                                    various prestigious positions such as President of the ACAAI (Air Cargo
                                    Agents
                                    Association of India) and the Air Cargo Club of Delhi, alongside serving as
                                    a
                                    Senior
                                    Advisor to the Delhi Customs Broker Association. </p>
                                <br />
                                <p>Recognized for his contributions, he was honoured with the "Mentor of the
                                    Year -
                                    2018" award by India Cargo Awards and the "Logistics Industry Ambassador
                                    Award - 2023." </p>
                                <br />
                                <p>Additionally, Mr. Sunil holds an MBA from IIMM, the Indian Institute of
                                    Marketing
                                    Management, and has undergone Basic & Advanced cargo training from IATA
                                    (International Air Transport Association), Geneva. He also possesses a CAT3
                                    -
                                    DGR
                                    (Dangerous Goods Regulations) certification, further enhancing his expertise
                                    in
                                    the field. </p>
                                <br />
                                <p className='ft-team-text--'>Mr. Sunil has actively worked with MoCA (Ministry of
                                    Civil Aviation) & MOC
                                    (Ministry of
                                    Commerce) on Industry Issues for over decades. He is part of all Logistics
                                    and
                                    infrastructure committees of National Organizations like CII (Confederation
                                    of
                                    Indian
                                    Industry), FICCI (Federation of Indian Chambers of Commerce and Industry),
                                    PHD
                                    Chambers of Commerce (PHD Chamber of Commerce and Industry). He is also an
                                    active
                                    member of the committee interacting with DGCA (Directorate General of Civil
                                    Aviation),
                                    BCAS (Bureau of Civil Aviation Security), and the Ministry of Commerce,
                                    Govt. of
                                    India. He
                                    has been actively involved at all senior level meetings with BAR (Board of
                                    Airline
                                    Representatives) - Airlines, Airport Custodians AERA (Airports Economic
                                    Regulatory
                                    Authority). </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
</div>
  )
}
