import React from 'react'
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';

export const Vision = () => {

  const supplyChainResponsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 1700, min: 1200 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 1200, min: 992 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 992, min: 768 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1
    }
}


  return (
     <section className="about-one">
                    <div className='container-fluid p-0'>
                        <div className='row'>
                            <div className='col-lg-5 cardians-implemented' >
                                <Carousel arrows={false}
                                    responsive={supplyChainResponsive}
                                    infinite={true}
                                    showDots={false}
                                    autoPlay={true}
                                    // customTransition="transform 0.5s ease-in-out"
                                    // containerClass="vertical-slider-container"
                                    // itemClass="vertical-slider-item"
                                    >
                                   
                                        <div className="swiper-slide position-relative">
                                            <img src="/images/resources/about-2.png" alt="" />
                                        </div>
                                        {/* <div className="swiper-slide position-relative">
                                            <img src="/images/mission-vision/eu-india-chamber.jpg" alt="" />
                                        </div>
                                        <div className="swiper-slide position-relative">
                                            <img src="/images/mission-vision/fiata-logo.png" alt="" />
                                        </div>
                                        <div className="swiper-slide position-relative">
                                            <img src="/images/mission-vision/tiaca.png" alt="" />
                                        </div> */}
                                </Carousel>
                            </div>
                            <div className='col-lg-7'>
                                <div className='about-vich-one'>
                                    <div className='section-title text-left'>
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">Our Vision</span>
                                        </div>
                                        {/* <h2 className="section-title__title">Seamless Connection for your <br></br> business needs</h2> */}
                                        <p className="about-one__text">We aspire to create a world where… global connectivity fuels sustainable growth, prosperity and harmony. We aim to overcome geographical barriers, stimulate innovation and uplift communities, thereby shaping a brighter future for generations to come. </p>
                                        {/* <a class="thm-btn main-menu__btn mt-5" href="/membership-registration">Grow with us<span class="omaga"></span></a> */}
                                        <Link to='/membership-registration' class="thm-btn main-menu__btn mt-5">Grow with us<span class="omaga"></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
  )
}
