import React, { useRef, useState } from 'react'
import {
    Grid,
    Paper,
    Button,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { Form, Formik } from 'formik';
import { ContactPersonModel } from 'model/screensModels/membershipRegistration/ContactPersonModel';
import ContactPersonSchema from 'schemas/membershipForm/ContactPersonSchema';
import { UploadFileService } from 'services/shared/UploadFileService';


const MembershipContactPerson = ({ memberlist, setMemberList }) => {

    const [initialValues, setInitialValues] = useState(new ContactPersonModel())
    const fileInputRef = useRef(null);
    const [isFormVisible, setIsFormVisible] = useState(true);

    const addMember = async (values, setFieldValue, setFieldTouched) => {
        const data = await createData(values.name, values.position, values.phoneNumber, values.email, values.contactPersonImage, values.contactIsrepresentative);
        //values.contactPerson.push(data)
        setMemberList(oldArray => [...oldArray, data])
        setFieldValue('name', '');
        setFieldTouched('name', false);
        setFieldValue('position', '');
        setFieldTouched('position', false);
        setFieldValue('phoneNumber', '');
        setFieldTouched('phoneNumber', false);
        setFieldValue('email', '');
        setFieldTouched('email', false);
        setFieldValue('contactPersonImage', '');
        setFieldTouched('contactPersonImage', false);
        setFieldValue('contactIsrepresentative', false);
        setFieldTouched('contactIsrepresentative', false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setIsFormVisible(false);

    }


    const createData = async (name, position, phoneNumber, email, contactPersonImage, contactIsrepresentative) => {
        //to get image url
        // if (contactPersonImage) {
        //     const formData = new FormData();
        //     formData.append("file", contactPersonImage);
        //     const imageurlData = await UploadFileService.getImagePath(formData);
        //     contactPersonImage = imageurlData.data.fileName;
        // } else {
        //     contactPersonImage = '';
        // }

        return { name, position, phoneNumber, email, contactPersonImage, contactIsrepresentative };
    }

    const removeMember = (index) => {
        setMemberList([
            ...memberlist.slice(0, index),
            ...memberlist.slice(index + 1)
        ]);
    }


    return (
        <>
            {isFormVisible ? (
                <>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={ContactPersonSchema}
                        onSubmit={async (values, { resetForm }) => {
                        }}
                    >
                        {({ handleSubmit, isValid, dirty, values, setFieldValue, setFieldTouched }) => (
                            <Form onSubmit={handleSubmit}>
                                <Typography variant="h4" align="center" marginBottom="30px">
                                    Contact Person
                                </Typography>
                                <div className='membership-contact--'>
                                            <div className='row mx-0'>
                                                <div className='col-md-6 member-form'>
                                                    <FormikControl
                                                        control="textfield"
                                                        className='form-control'
                                                        name="name"
                                                        label="Name"
                                                        placeholder="Enter Your Name"
                                                        requiredField
                                                    />
                                                </div>
                                                <div className='col-md-6 member-form'>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="position"
                                                        className='form-control'
                                                        label="Position / Designation"
                                                        placeholder="Enter Your Position / Designation"
                                                        requiredField
                                                    />
                                                </div>
                                                <div className='col-md-6 member-form'>
                                                    <FormikControl
                                                        control="phoneInputField"
                                                        name="phoneNumber"
                                                        // className='form-control'
                                                        label="Phone Number"
                                                        placeholder="Enter Your Contact Number"
                                                        requiredField
                                                    />
                                                </div>
                                                <div className='col-md-6 member-form'>
                                                    <FormikControl
                                                        control="textfield"
                                                        name="email"
                                                        className='form-control'
                                                        label="Email Id"
                                                        placeholder="Enter Your Email Id"
                                                        requiredField
                                                    />

                                                </div>
                                                <div className='col-md-6 member-form position-relative'>
                                                    <FormikControl
                                                        control="uploadImage"
                                                        name="contactPersonImageFile"
                                                        nameUrl="contactPersonImage"
                                                        className='form-control file-input'
                                                        label="Image (dimensions 300x300px & upto 100kb.)"
                                                        inputRef={fileInputRef}
                                                    // placeholder="Enter Your Email Id"
                                                    // requiredField
                                                    />
                                                    {/* <p className='error-msg-img'>Image dimensions 300x300px & upto 100kb.</p> */}
                                                </div>
                                                <div className='col-md-6 member-form'>
                                                    <FormikControl
                                                        className="radiobutton"
                                                        control="radiogroup"
                                                        label="Is an Authoritative Representative"
                                                        name="contactIsrepresentative"
                                                        options={[
                                                            { key: "Yes", value: true },
                                                            { key: "No", value: false },
                                                        ]}
                                                        requiredField
                                                        row
                                                    />
                                                </div>
                                                <div className='col-12 mb-2 d-flex justify-content-end'>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        className='add-member-btn'
                                                        onClick={(e) => addMember(values, setFieldValue, setFieldTouched)}
                                                        disabled={!dirty || !(isValid)}
                                                    >Add Member</Button>
                                                </div>
                                            </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    {memberlist.length > 0 && (
                        <div className="d-flex my-3">
                            <Button variant="contained" className="btn btn-submit rounded-2" onClick={() => setIsFormVisible(false)}>
                            View Added Member Details
                            </Button>
                        </div>
                    )}
                </>
            ) :
                (
                    <div className='col-lg-12 mui-table-block-body'>
                        <div className='row'>
                            {memberlist?.length > 0 ?
                                <div className='mui-tables'>
                                    <TableContainer className='mui-tables-body' component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="right">Position</TableCell>
                                                    <TableCell align="right">Mobile no.</TableCell>
                                                    <TableCell align="right">Email</TableCell>
                                                    <TableCell align="right">Representative</TableCell>
                                                    <TableCell align="right">Action</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {memberlist.map((member, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{member.name}</TableCell>
                                                        <TableCell align="right">{member.position}</TableCell>
                                                        <TableCell align="right">{member.phoneNumber}</TableCell>
                                                        <TableCell align="right">{member.email}</TableCell>
                                                        <TableCell align="right">{member.contactIsrepresentative ? 'Yes' : 'No'}</TableCell>
                                                        <TableCell align="right"><DeleteIcon onClick={() => { removeMember(index) }} color="action" /></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                : ""}
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="contained" className="btn btn-submit rounded-2 mb-3" onClick={() => setIsFormVisible(true)}>
                            Add More Details
                            </Button>
                        </div>
                    </div>
                )}
        </>
    )
}

export default MembershipContactPerson
