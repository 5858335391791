import { Link } from 'react-router-dom';

const FAQs = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header faq-banner-img member-section">
                <img src="/images/banner/faqs-banner.png"></img>
                <div className="page-header__bg faqs-banner">
                </div>
                {/* <div className="container">
                    <div className="page-header__inner">
                        <h2>FAQs</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                              
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>FAQs</li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </section>
            {/* //   <!--Page Header End--> */}

            {/* <!-- Start of faq  section
	============================================= --> */}
            <section class="faqs-padding">
                <div class="container">
                    <div class="ft-faq-page-top-content d-flex justify-content-center align-items-end flex-wrap">
                        <div class="ft-section-title-2 headline pera-content">
                            <h2>Discover Frequently
                                Asked Questions from
                                <span> Our Support</span>
                            </h2>
                        </div>
                    </div>
                    <div class="ft-faq-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-9">
                                <div class="accordion" id="accordionExample">
                                    <div className='faq-background'>
                                        <h2 className='faq-heading'>Frequently Asked Questions Related to <span className='global---'>Website</span></h2>
                                        {/* faqs 1 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Where are the registered offices of 1WN located?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>  <strong>One World Network</strong> has two registered offices given as below: </p>
                                                    <p> <strong>ONE WORLD NETWORK – Headquarters <br></br></strong>
                                                        #511, 5th Floor, 8W Building DAFZ,
                                                        Dubai 500001, United Arab Emirates (UAE) </p>
                                                    <p> <strong>ONE WORLD NETWORK – Regional Office <br></br></strong>
                                                        Radisson Hotel, Delhi Airport,
                                                        New Delhi-37, India </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 1 */}
                                        {/* faqs 2 */}
                                        {/* <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How many members and countries and ports?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>   The 1WN global network will accommodate up to 500 members worldwide, with a condition that no more
                                                    than 10% of the total membership shall come from any one country at any time.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* faqs 2 */}
                                        {/* faqs 3 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    What is the annual fee to join 1WN?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        1WN's joining annual fee structure is as follows:
                                                        <ul>
                                                            <li>USD$2000 for 1 year</li>
                                                            <li>USD$3500 for 2 years</li>
                                                            <li>USD$5000 for 3 years</li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 3 */}
                                        {/* faqs 4 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwentyfive" aria-expanded="false" aria-controls="collapseThree">
                                                    How to join the 1WN?
                                                </button>
                                            </h2>
                                            <div id="collapsetwentyfive" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        You can Visit us on <a href="www.oneworldnetwork.co" target="_blank" rel="noopener noreferrer">www.oneworldnetwork.co</a> or to know in detail you can
                                                        write us on <a href="mailto:sales@oneworldnetwork.co" target="_blank" rel="noopener noreferrer">sales@oneworldnetwork.co</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 4 */}
                                        {/* faqs 5 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour1" aria-expanded="false" aria-controls="collapseFour">
                                                    How often do you hold conferences?
                                                </button>
                                            </h2>
                                            <div id="collapseFour1" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Once a year.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 5 */}
                                        {/* faqs 6 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour6" aria-expanded="false" aria-controls="collapseFour">
                                                    Who can be a 1WN member?
                                                </button>
                                            </h2>
                                            <div id="collapseFour6" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        We invite Logistics Service Providers, Freight Forwarders, Customs
                                                        Brokers, Multimodal Operators, Road & Rail Transport Companies, Supply
                                                        Chain Firms, Warehousing and Distribution Services, Technology Partners,
                                                        Freight Insurance Providers, and other industry-related partners. However,
                                                        all applicants must meet our established standards and requirements.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 7 */}
                                        {/* faqs 8 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour12" aria-expanded="false" aria-controls="collapseFour">
                                                    Does 1WN have a limit of members in each country?
                                                </button>
                                            </h2>
                                            <div id="collapseFour12" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Yes. 1WN is trying our best to recruit more high-quality members in the
                                                        main countries with a limit of not more than 10% of total membership from
                                                        one country.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 8 */}
                                        {/* faqs 9 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour4" aria-expanded="false" aria-controls="collapseFour">
                                                    What is the expected total membership size for 1WN?
                                                </button>
                                            </h2>
                                            <div id="collapseFour4" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        The 1WN Global Network will include up to 500 members globally, with the
                                                        condition that no more than 10% of the total membership can be from any
                                                        single country at any given time
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 9 */}
                                        {/* faqs 10 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour5" aria-expanded="false" aria-controls="collapseFour">
                                                    Does 1WN offer free trials?
                                                </button>
                                            </h2>
                                            <div id="collapseFour5" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        1WN does not offer free trials in order to protect our paid members.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 10 */}
                                        {/* faqs 11 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour7" aria-expanded="false" aria-controls="collapseFour">
                                                    Does the Membership fee includes the conference fees as well?
                                                </button>
                                            </h2>
                                            <div id="collapseFour7" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>  The membership fee <strong>does not </strong>include the conference fee. </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 11 */}
                                        {/* faqs 12 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour8" aria-expanded="false" aria-controls="collapseFour">
                                                    Do 1WN hold an annual conference? If so, when and where is it held?
                                                </button>
                                            </h2>
                                            <div id="collapseFour8" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>  <strong>Yes</strong>, 1WN hosts an annual conference each year, fostering global
                                                        connections and overcoming international business barriers. The
                                                        upcoming conference is scheduled for <strong>April 2025 in Dubai</strong>, where
                                                        members from around the world will have the opportunity to meet and
                                                        collaborate." </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 12 */}
                                        {/* faqs 13 */}

                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourtySix" aria-expanded="false" aria-controls="collapseOne">
                                                    What certifications or accreditations does 1WN hold?
                                                </button>
                                            </h2>
                                            <div id="collapseFourtySix" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>   1WN holds several prestigious certifications and accreditations, that
                                                        enable us to maintain the highest standards of service and ensure that our
                                                        members benefit from a globally recognized and reputable network. These
                                                        include:
                                                        <ul>
                                                            <li>ACAAI (Air Cargo Agents Association of India) </li>
                                                            <li>FIATA (International Federation of Freight Forwarders Associations) </li>
                                                            <li>EU Chamber of Commerce </li>
                                                            <li>TIACA (The International Air Cargo Association) </li></ul></p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 1 */}
                                        {/* faqs 2 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What is the difference between 1WN and other networks?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>   The world's most rapidly expanding network of international logistics firms and independent freight
                                                        forwarders. <br></br>
                                                        <ul>
                                                            <li>Cloverleaf service</li>
                                                            <li>Membership limits:&nbsp; Restricted per country, proportional to the country's EXIM figures. </li>
                                                            <li>Supported by a board of advisors comprising industry experts and seasoned logisticians from
                                                                airlines, shipping lines, and government sectors. </li>
                                                            <li>1WN offers financial protection of USD 25,000 per year. </li>
                                                            <li>Easy-to-use Management System. </li>
                                                            <li>Robust, efficient real-time communication platform for quotes and replies. </li>
                                                        </ul></p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 2 */}
                                        {/* faqs 3 */}

                                        {/* faqs 3 */}
                                        {/* faqs 4 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    How do you qualify "Trusted and professional global freight forwarding
                                                    partners" within 1WN?
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Each prospective member company is required to submit strong
                                                        commercial references to satisfy the requirements of the management. <br></br>
                                                        Team 1WN also inspects and verifies the background and the authorised
                                                        owner of the company through various sources before turning the
                                                        potential prospect into a new member. <br></br>
                                                        Additionally, it is imperative for you to prove your expertise in your local
                                                        markets and ensure that you have a positive reputation with other agents
                                                        and carriers as well as that your company has professional leadership with
                                                        integrity in order to be enrolled.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 4 */}
                                        {/* faqs 5 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour13" aria-expanded="false" aria-controls="collapseFour13">
                                                    How many agents do you accept?
                                                </button>
                                            </h2>
                                            <div id="collapseFour13" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Our primary criteria for accepting agents is based on EXIM data, which
                                                        provides insights into a country's import and export activities. However, this
                                                        may vary depending on other exceptional factors.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 5 */}
                                        {/* faqs 6 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour6" aria-expanded="false" aria-controls="collapseFour6">
                                                    Are your member's details made public and can non-members gain
                                                    access to 1WN member's details?
                                                </button>
                                            </h2>
                                            <div id="collapseFour6" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Details about 1WN members are inaccessible to non-members.
                                                        Information about data shall not be marked as public.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 7 */}
                                        {/* faqs 8 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour12" aria-expanded="false" aria-controls="collapseFour">
                                                    What PR and marketing services does 1WN provide for its members?
                                                </button>
                                            </h2>
                                            <div id="collapseFour12" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        One World Network actively promotes its members across a variety of
                                                        platforms. <br></br>
                                                        Upon the addition of a new member, we introduce them our various social
                                                        media handles such as LinkedIn, Instagram etc. Additionally, 1WN Portal
                                                        allow members to upload their milestones and achievements which will
                                                        then be reviewed by 1WN internal team. Post review these milestones are
                                                        shared with other members through newsletters, Network News, other
                                                        social media channels and media partners.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 8 */}
                                        {/* faqs 9 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour4" aria-expanded="false" aria-controls="collapseFour">
                                                    What industries or sectors does 1WN focus on?
                                                </button>
                                            </h2>
                                            <div id="collapseFour4" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        1WN specializes in seven key areas to meet the varied needs of our
                                                        global clients to offer flexible, efficient, and industry-specific logistics
                                                        solutions worldwide:
                                                        <ul>
                                                            <li><strong>1WN Global  </strong></li>
                                                            <li><strong>1WN Personal  </strong></li>
                                                            <li><strong>1WN Project  </strong></li>
                                                            <li><strong>1WN Express  </strong></li>
                                                            <li><strong>1WN Dangerous Goods   </strong></li>
                                                            <li><strong>1WN Temperature  </strong></li>
                                                            <li><strong>1WN NVOCC  </strong></li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 9 */}
                                        {/* faqs 10 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour5" aria-expanded="false" aria-controls="collapseFour">
                                                    There are multiple vertical networks within 1WN, if I join 1WN network
                                                    does this give me access to all of 1WN Logistics Networks?
                                                </button>
                                            </h2>
                                            <div id="collapseFour5" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Each network operates independently, with its own set of members and
                                                        unique membership fees. Joining one network does not grant automatic
                                                        access to all of 1WN’s logistics networks."
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour3" aria-expanded="false" aria-controls="collapseFour">
                                                    How can I get in touch with someone to discuss membership options
                                                    and ask any Questions I may have?
                                                </button>
                                            </h2>
                                            <div id="collapseFour3" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        For any inquiries regarding membership options, please feel free to
                                                        contact us at <a href="mailto:sales@oneworldnetwork.co" target="_blank" rel="noopener noreferrer">sales@oneworldnetwork.co</a> . We will respond to your query
                                                        within 24 hours.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 5 */}
                                        {/* faqs 6 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour15" aria-expanded="false" aria-controls="collapseFour">
                                                    How many members are currently part of your network?
                                                </button>
                                            </h2>
                                            <div id="collapseFour15" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Currently, our network comprises over 65+ members, with businesses
                                                        spanning across various regions and industries. We continue to grow as we
                                                        connect like-minded professionals and expand our global reach.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 7 */}
                                        {/* faqs 8 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour16" aria-expanded="false" aria-controls="collapseFour">
                                                    What if I want to do business in a country where I don’t speak the
                                                    native language? Will 1WN assist with overcoming this barrier?
                                                </button>
                                            </h2>
                                            <div id="collapseFour16" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        At 1WN, we pride ourselves on our unique strength of having a diverse
                                                        team of international language speakers. This allows us to facilitate
                                                        seamless communication and help you overcome any language barriers
                                                        when conducting business in countries where you may not speak the
                                                        native language. Our network ensures that you can exchange business
                                                        queries effortlessly and effectively, no matter the language.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 8 */}
                                        {/* faqs 9 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour17" aria-expanded="false" aria-controls="collapseFour">
                                                    Do you accept agents who are currently members of other networks?
                                                </button>
                                            </h2>
                                            <div id="collapseFour17" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Yes, we welcome agents who are currently members of other networks.
                                                        As we value the diversity, collaboration and expertise that agents from
                                                        various networks can bring to our community.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* faqs 9 */}
                                        {/* faqs 10 */}
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour18" aria-expanded="false" aria-controls="collapseFour">
                                                    What is the major difference between Founder Member and an
                                                    Exclusive Member?
                                                </button>
                                            </h2>
                                            <div id="collapseFour18" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        <strong> Founder Members</strong> are the pioneering members who join 1WN during the
                                                        early stages of the network’s establishment. These members play a crucial
                                                        role in shaping the direction of the network and enjoy exclusive benefits,
                                                        such as priority access to new opportunities, special recognition, and
                                                        enhanced visibility within the network.
                                                    </p>
                                                    <p>
                                                        <strong>Exclusive Members</strong> on the other hand, are those who join after the
                                                        network has established its foundation. While they still receive significant
                                                        benefits, such as access to global business opportunities and industry
                                                        resources, Exclusive Members do not have the same founding privileges as
                                                        Founder Members.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>






                                    <div className='faq-background'>

                                        <h2 className='faq-heading--'>Frequently Asked Questions Related to <span className='global---'>Portal</span></h2>


                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour19" aria-expanded="false" aria-controls="collapseFour">
                                                    What are the features of 1WN Portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour19" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        The 1WN portal offers a wide range of features designed to enhance
                                                        connectivity, collaboration, and efficiency for our members. Some of the
                                                        key features are:
                                                        <ul>
                                                            <li>Global Member Directory</li>
                                                            <li>Real-Time Communication Tools </li>
                                                            <li>Customizable Profile Management </li>
                                                            <li>Matching Business Opportunities </li>
                                                            <li>Document Sharing & Collaboration</li>
                                                            <li>Integrated Payment & Financial Tracking </li>
                                                            <li>Exclusive Industry Insights </li>
                                                            <li>Secure & User-Friendly Interface </li>
                                                            <li>Robust Quote sharing system</li>
                                                            <li>Team Reports & Insights </li>
                                                        </ul>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour20" aria-expanded="false" aria-controls="collapseFour">
                                                    How does the 1WN Portal work?
                                                </button>
                                            </h2>
                                            <div id="collapseFour20" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        The 1WN Portal is a comprehensive platform designed to facilitate
                                                        seamless communication and business transactions among members of
                                                        the network.
                                                    </p>
                                                    <p>
                                                        Once registered, members can access the portal to connect with other
                                                        global logistics professionals, exchange quotes, track business
                                                        transactions, and collaborate on various logistics solutions. The portal also
                                                        features real-time updates, a secure messaging system, and tools to help
                                                        members manage their profiles, monitor financial transactions, and
                                                        access industry insights.
                                                    </p>
                                                    <p>With an intuitive interface and user-friendly design, the 1WN Portal
                                                        simplifies networking and supports members in expanding their global
                                                        business operations </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour21" aria-expanded="false" aria-controls="collapseFour">
                                                    How can we interact or communicate with the other members?
                                                </button>
                                            </h2>
                                            <div id="collapseFour21" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Members can interact and communicate with one another through the
                                                        1WN Logistics portal. This platform enables seamless collaboration,
                                                        messaging, and sharing of information among network members.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour22" aria-expanded="false" aria-controls="collapseFour">
                                                    What happens if there are no members in a specific region?
                                                </button>
                                            </h2>
                                            <div id="collapseFour22" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        In cases where we do not have a registered member in a specific region,
                                                        and one of our onboarded members wishes to conduct business in that
                                                        area, we will introduce them to a potential prospect. However, it is
                                                        important for our members to understand that, despite this introduction,
                                                        the prospect will remain a potential business partner and not a registered
                                                        network member. Therefore, it is the responsibility of the onboarded
                                                        member to secure the transaction and ensure financial protection, as this
                                                        business deal will not be covered under 1WN's financial protection policy.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour23" aria-expanded="false" aria-controls="collapseFour">
                                                    What is the maximum number of branch offices you can register with
                                                    1WN?
                                                </button>
                                            </h2>
                                            <div id="collapseFour23" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        We focus on building a high-quality network by onboarding a selected
                                                        number of partners from each country. While we prioritize the global
                                                        geographical presence of potential members, we also understand that
                                                        businesses may require multiple locations to effectively serve global
                                                        markets. As such, there is no fixed limit on the number of branch offices you
                                                        can register with 1WN. However, it completely depends up on its
                                                        geographical presence and strategic alignment with the network.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour24" aria-expanded="false" aria-controls="collapseFour">
                                                    Why should you provide Announcement details on the Portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour24" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        <strong>‘Announcement details are an excellent way to highlight your
                                                            achievements in the logistics industry’.</strong> Sharing such updates on the
                                                        portal not only boosts your visibility within the network but also enhances
                                                        your profile in the industry. It also serves as a powerful marketing and
                                                        promotional tool.
                                                    </p>
                                                    <p>
                                                        When you post an announcement on the portal. We actively promote your
                                                        shared content across our various promotion channels like social media
                                                        platforms, feature it in the highly regarded Cargo Insights industry
                                                        magazine, and publish it in our editorials. This approach ensures that your
                                                        accomplishments gain maximum exposure, positioning your company for
                                                        greater recognition and business opportunities.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour25" aria-expanded="false" aria-controls="collapseFour">
                                                    What happens if you don’t answer to any received quotes?
                                                </button>
                                            </h2>
                                            <div id="collapseFour25" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        In cases where a quote remains unanswered, we will follow up with the
                                                        recipient to understand the reason for the lack of response. After 48 hours,
                                                        we will inform the sender of the situation. If the recipient declines the query
                                                        and the sender is specifically interested in doing business in that region,
                                                        the sender may forward the quote to the admin, and we will connect them
                                                        with a potential prospect. However, it is important to note that although the transaction will involve a registered member, the recipient will not be
                                                        considered part of the 1WN network. As such, the transaction will not be
                                                        covered under 1WN's financial protection policy, and the member is
                                                        advised to safeguard the transaction independently.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour26" aria-expanded="false" aria-controls="collapseFour">
                                                    What is the financial protection amount offered by 1WN?
                                                </button>
                                            </h2>
                                            <div id="collapseFour26" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        1WN offers a financial protection amount up to <strong>$25,000 per year</strong> to its
                                                        members, ensuring a level of security for transactions conducted within
                                                        the network.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour27" aria-expanded="false" aria-controls="collapseFour">
                                                    Is there a limit on the number of business transactions you can
                                                    conduct with our partners?
                                                </button>
                                            </h2>
                                            <div id="collapseFour27" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        There is <strong>no limit</strong> on the number of business transactions you can
                                                        conduct with partners in the 1WN network. We encourage unlimited
                                                        collaboration to support your growth and success.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour28" aria-expanded="false" aria-controls="collapseFour">
                                                    How long will it take to receive your financial protection payout?
                                                </button>
                                            </h2>
                                            <div id="collapseFour28" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Usually, it takes up to <strong>60 days</strong> to process and receive your financial
                                                        protection payout. However, if the investigation into the case requires
                                                        additional time, the payout <strong>may take longer than the standard 60 days</strong>.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour29" aria-expanded="false" aria-controls="collapseFour">
                                                    How can you recover forgotten portal password?
                                                </button>
                                            </h2>
                                            <div id="collapseFour29" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        If you have forgotten your portal password, simply click on the 'Forgot
                                                        Password' link on the login page. You will be prompted to enter your User Id,
                                                        and a new password will be issued to you from 1WN via mail within 24hrs. In
                                                        addition, you can write us on <a href="mailto:cs@onworldnetwork.co" target="_blank" rel="noopener noreferrer">cs@onworldnetwork.co</a> to help you reset the
                                                        password.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour30" aria-expanded="false" aria-controls="collapseFour">
                                                    What if any member back off in the middle of the transaction.
                                                </button>
                                            </h2>
                                            <div id="collapseFour30" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p><strong>In the case where a member backs out during an ongoing transaction,
                                                        1WN's Financial Protection Policy is in place to safeguard against
                                                        financial loss or damage. </strong>
                                                    </p>
                                                    <p>This policy offers coverage up to $25,000 per year to protect against any
                                                        unforeseen issues that may arise. </p>
                                                    <p>However, it’s important to note that if the transaction is conducted
                                                        between a member and a potential prospect (i.e., someone not yet fully
                                                        onboarded as a member), this transaction will not be covered under the
                                                        Financial Protection Policy. The coverage only applies to transactions
                                                        between fully onboarded members of the network. </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour31" aria-expanded="false" aria-controls="collapseFour">
                                                    How can you boost your visibility on the portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour31" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        You can consider the below mention steps to enhance your visibility on
                                                        the 1WN portal.
                                                        <ul>
                                                            <li>Ensure a complete and polished profile.</li>
                                                            <li>Be proactive with quotes & business queries.</li>
                                                            <li>Engage actively within the network.</li>
                                                            <li>Share key updates and announcements to keep your network
                                                                informed.</li>
                                                            <li>Highlight your credentials that reflects your expertise and credibility.</li>
                                                            <li>Maintain consistent in making payments.</li>
                                                        </ul>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour32" aria-expanded="false" aria-controls="collapseFour">
                                                    What should you do if the status of your inward invoice changed from
                                                    outstanding to overdue?
                                                </button>
                                            </h2>
                                            <div id="collapseFour32" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Once the status of the invoice changed from outstanding to overdue, it
                                                        becomes imperative to remit payment for the invoiced amount. In the
                                                        event that you have already made the payment, you are encouraged to
                                                        reach out either to the 1WN Portal Admin or to the sender of the invoice for
                                                        clarification.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour33" aria-expanded="false" aria-controls="collapseFour">
                                                    What should you do if your paid inward invoice still reflects as
                                                    outstanding or overdue on portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour33" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        If your paid inward invoice still appears as "Outstanding" or "Overdue" on
                                                        the portal, we recommend first reaching out to the trading member to
                                                        request an update on the status. Alternatively, you can contact our admin
                                                        team directly at <a href="mailto:cs@oneworldnetwork.co" target="_blank" rel="noopener noreferrer">cs@oneworldnetwork.co</a> for assistance in resolving the
                                                        issue.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour34" aria-expanded="false" aria-controls="collapseFour">
                                                    How can you change your company details on 1WN Portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour34" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        In order to modify the company details on the 1WN Portal, please navigate
                                                        to the Company Profile section and select the option to edit the profile. You
                                                        will have the ability to update various details however, it is important to
                                                        note that certain information can only be amended by 1WN Admin.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour35" aria-expanded="false" aria-controls="collapseFour">
                                                    What is Admin Chat and Admin Quote and How does it work?
                                                </button>
                                            </h2>
                                            <div id="collapseFour35" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        1WN Admin Chat service provides users with direct access to
                                                        administrative support for any inquiries related to the 1WN Portal. Admin will
                                                        respond to all questions within a period of 24 hours.
                                                    </p>
                                                    <p>The 1WN Admin Quote feature enables users to submit their quotes directly
                                                        to the admin team. The admin, will then connect you with an agent for the
                                                        assistance of these quotes. </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour36" aria-expanded="false" aria-controls="collapseFour">
                                                    Can we give references of other agents?
                                                </button>
                                            </h2>
                                            <div id="collapseFour36" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Yes, you can refer other agents to us. However, their onboarding will be
                                                        subject to several criteria. As we focus on building a premium-quality
                                                        network, we selectively onboard companies that meet specific
                                                        requirements. If the referred agents meet these criteria, we would be
                                                        happy to welcome them to our network. </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour37" aria-expanded="false" aria-controls="collapseFour">
                                                    What should you do if you face technical issues on the portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour37" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        If you encounter any technical issues, please email us at
                                                        <a href="cs@oneworldnetwork.co"> cs@oneworldnetwork.co</a> for assistance. For urgent matters, you can also
                                                        reach us directly at <a href="tel:+91 7303733554" target="_blank" rel="noopener noreferrer">+91 7303733554</a>. We're here to help! </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour38" aria-expanded="false" aria-controls="collapseFour">
                                                    What should you do if you are facing any Grievance related issue on
                                                    portal?
                                                </button>
                                            </h2>
                                            <div id="collapseFour38" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Your grievance will usually be approved within 60 days after the
                                                        investigation. However, if you are still facing any grievance-related issues
                                                        on the portal, please feel free to contact our admin team directly at
                                                        <a href="mailto:cs@oneworldnetwork.com" target="_blank" rel="noopener noreferrer"> cs@oneworldnetwork.com</a>. If you require urgent assistance, you can also
                                                        reach us at <a href="tel:+91-7303733554" target="_blank" rel="noopener noreferrer">+91-7303733554</a>.  </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className='faq-background'>
                                        <h2 className='faq-heading--'>Frequently Asked Questions Related to <span className='global---'>Conference</span></h2>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour39" aria-expanded="false" aria-controls="collapseFour">
                                                    What if we don’t want any Accommodations?
                                                </button>
                                            </h2>
                                            <div id="collapseFour39" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Accommodations are mandatory for all attendees traveling from
                                                        outside the conference’s host country. However, if you are residing in the
                                                        same country where the conference is being held, you may choose to opt
                                                        out of the accommodation. </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour40" aria-expanded="false" aria-controls="collapseFour">
                                                    Do I have to compulsorily stay in accommodation even if I reside in the
                                                    country of Conference?
                                                </button>
                                            </h2>
                                            <div id="collapseFour40" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Attendees who are residing in the host country of the conference are not
                                                        required to stay in the accommodation provided by us. They may opt for
                                                        alternative arrangements if preferred. </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour41" aria-expanded="false" aria-controls="collapseFour">
                                                    How can we stay informed about the latest updates and details
                                                    regarding the conference?
                                                </button>
                                            </h2>
                                            <div id="collapseFour41" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>For the most recent updates regarding the conference, we strongly
                                                        encourage you to follow our social media channels and visit our website at
                                                        <a href="oneworldnetwork.co" target="_blank" rel="noopener noreferrer"> oneworldnetwork.co</a>  </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour42" aria-expanded="false" aria-controls="collapseFour">
                                                    When and where is the 1WN international conference 2025?
                                                </button>
                                            </h2>
                                            <div id="collapseFour42" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        The 1WN International Conference 2025 will be held in <strong> Dubai in April
                                                            2025</strong>. Where we prepare to bring together industry leaders and innovators
                                                        from around the globe. </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour43" aria-expanded="false" aria-controls="collapseFour">
                                                    How you can be a sponsor in the 1WN Conference?
                                                </button>
                                            </h2>
                                            <div id="collapseFour43" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        1WN offer multiple categories for sponsorship to know about them
                                                        please visit our website at <a href="oneworldnetwork.co" target="_blank" rel="noopener noreferrer">oneworldnetwork.co</a> or send a mail to
                                                        <a href="mailto:sales@oneworldnetwork.co" target="_blank" rel="noopener noreferrer"> sales@oneworldnetwork.co</a>.  </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour44" aria-expanded="false" aria-controls="collapseFour">
                                                    How you can register yourself as a speaker in 1WN conference?
                                                </button>
                                            </h2>
                                            <div id="collapseFour44" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        To register as a speaker for the 1WN Conference 2025, it is necessary to
                                                        complete the conference registration form. For any additional information,
                                                        please feel free to contact <a href="mailto:sales@oneworldnetwork.co" target="_blank" rel="noopener noreferrer">sales@oneworldnetwork.co</a>.</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour45" aria-expanded="false" aria-controls="collapseFour">
                                                    Where can you register for 1WN international conference 2025?
                                                </button>
                                            </h2>
                                            <div id="collapseFour45" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        You can register for the 1WN International Conference 2025 in Dubai by
                                                        visiting our official website at <a href="www.oneworldnetwork.co" target="_blank" rel="noopener noreferrer">www.oneworldnetwork.co</a> or send a mail at
                                                        <a href="mailto:Sales@oneworldnetwork.co" target="_blank" rel="noopener noreferrer"> Sales@oneworldnetwork.co</a>  </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour46" aria-expanded="false" aria-controls="collapseFour">
                                                    Where can you find more information about the upcoming
                                                    conference?
                                                </button>
                                            </h2>
                                            <div id="collapseFour46" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        For more details about the Conference, you can visit our website at
                                                        <a href="www.oneworldnetwork.co" target="_blank" rel="noopener noreferrer"> www.oneworldnetwork.co</a>    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item headline pera-content">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour47" aria-expanded="false" aria-controls="collapseFour">
                                                    Can I attend the 1WN conference if I am not a 1WN member?
                                                </button>
                                            </h2>
                                            <div id="collapseFour47" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sure, even if you are not a 1WN member still you can enrol yourself under
                                                        the “Observer” category on our website <a href="www.oneworldnetwork.co" target="_blank" rel="noopener noreferrer">www.oneworldnetwork.co</a>. 1WN's
                                                        conference aims to assist all logistics companies. although the fee varies
                                                        for attendees under the “Observer” category. </p>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* <!-- End of faq section
	============================================= --> */}

        </>
    )
}

export default FAQs