import React from 'react'
import { Link } from 'react-router-dom'

const FixedButtons = () => {
    return (
        <div>
            {/* Click to top */}
            {/* <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fas fa-arrow-up"></i></a> */}

            {/* join us button */}

            {/* <div id="fixed-btn">
                <div>
                    <a href="membership-benifits" class="fixed-catalog">
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn">
                                <span class="catalog-text">join Us</span></span>
                        </div>
                    </a>
                </div>
                <div><a href="https://wa.me/+917303733554" target="_blank" title="Click to connect on whatsapp"
                    class="whatsapp-icon-img">
                    <img alt="waapLogo"
                        src="images/icon/whatsapp-logo.png" class="whatsappWidth" /></a>
                </div>
            </div> */}

            {/* join us button */}

            <div id="fixed-btn">

                <div className='social-media'>
                    {/* <a href="membership-benifits" class="fixed-catalog" target='blank'> */}
                    <a href='https://www.facebook.com/1wn.co' target="_blank" rel="noopener noreferrer" className="fixed-catalog" >
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn facebook">
                            <img src="/images/icon/facebook.png"/>
                                <span class="catalog-text">Facebook</span></span>
                        </div>
                    </a>
                </div>
                <div className='social-media'>
                    <a href='https://www.instagram.com/1wn.co/' target="_blank" rel="noopener noreferrer" className="fixed-catalog" >
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn instagram">
                                <img src="/images/icon/instagram.png"/>
                                <span class="catalog-text">Instagram</span></span>
                        </div>
                    </a>
                </div>
                <div className='social-media'>
                    {/* <a href="membership-benifits" class="fixed-catalog"  target='blank'> */}
                    <a href='https://www.linkedin.com/company/1wn-one-world-one-network/' target="_blank" rel="noopener noreferrer" className="fixed-catalog" >
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn linkdin">
                            <img src="/images/icon/linkedin.png"/>
                                <span class="catalog-text">Linkedin</span></span>
                        </div>
                    </a>
                </div>
                <div className='social-media'>
                    {/* <a href="membership-benifits" class="fixed-catalog"  target='blank'> */}
                    <a href='https://www.youtube.com/@1wn_co' target="_blank" rel="noopener noreferrer" className="fixed-catalog" >
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn youtube">
                            <img src="/images/icon/youtube.png"/>
                                <span class="catalog-text">Youtube</span></span>
                        </div>
                    </a>
                </div>
                <div className='social-media'>
                    <a href="membership-benifits" class="fixed-catalog"  target='blank'>
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn">
                            <img src="/images/icon/join-us.png"/>
                                <span class="catalog-text">join Us</span></span>
                        </div>
                    </a>
                </div>
                <div className='social-media'>
                    <a href="https://wa.me/+917303733554" className="fixed-catalog"  target='blank'>
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn">
                            <img src="/images/icon/whatsapp-logo.png"/>
                                <span class="catalog-text">Whatsapp</span></span>
                        </div>
                    </a>
                </div>
                {/* <div><a href="https://wa.me/+917303733554" target="_blank" title="Click to connect on whatsapp"
                    class="whatsapp-icon-img">
                    <img alt="waapLogo"
                        src="images/icon/whatsapp-logo.png" class="whatsappWidth" /></a>
                </div> */}
                <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fas fa-arrow-up"></i></a>
            </div>
        </div>
    )
}

export default FixedButtons
