import { Link } from "react-router-dom";


const Blog = () => {
    return (
        <>




            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg blog-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Blog</h2>
                        <div className="thm-breadcrumb__box">
                            <ul classNameName="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Blog</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            <section className="section-blog">
                <div className="container-fluid p-5">

                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row reveal fade-bottom active">
                                {/* <div className="slider-list owl-carousel"> */}
                                <div className="col-lg-4">
                                    <div className="blog-update">
                                        <div className="blog-image">
                                            <a href="mobile-blog.html"> <img
                                                src="https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149051555.jpg?t=st=1734006778~exp=1734010378~hmac=e7da03e96a41121ce480433e9cdc5de00b8fd10e2e10377196b84c12f03133b6&w=996" /></a>
                                        </div>
                                        <div className="blog-content">
                                            <a href="mobile-blog.html">
                                                <h4>How is a mobile app different from a web application?</h4>
                                            </a>
                                            <p>Mobile and web applications have become essential tools for businesses to connect
                                                with customers and boost their digital presence.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="blog-update">
                                        <div className="blog-image">
                                            <a href="ui-blog.html">
                                                <img
                                                    src="https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149051555.jpg?t=st=1734006778~exp=1734010378~hmac=e7da03e96a41121ce480433e9cdc5de00b8fd10e2e10377196b84c12f03133b6&w=996" />
                                            </a>
                                        </div>
                                        <div className="blog-content">
                                            <a href="ui-blog.html">
                                                <h4>The Role of UX/UI Design in Building User-Friendly Websites
                                                </h4>
                                            </a>
                                            <p>Where first impressions matter more than ever, the role of UX/UI design in
                                                creating user-friendly websites cannot be overstated.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="blog-update">
                                        <div className="blog-image">
                                            <a href="digital-blog.html">
                                                <img
                                                    src="https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149051555.jpg?t=st=1734006778~exp=1734010378~hmac=e7da03e96a41121ce480433e9cdc5de00b8fd10e2e10377196b84c12f03133b6&w=996" />
                                            </a>
                                        </div>
                                        <div className="blog-content">
                                            <a href="digital-blog.html">
                                                <h4>What all things are included in Digital Marketing?</h4>
                                            </a>
                                            <p>As more consumers turn to the internet to discover products and services, digital
                                                marketing plays a vital role for brands to engage with their audience.</p>
                                        </div>
                                    </div>
                                </div>


                                {/* </div> */}
                            </div>

                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}

export default Blog