import React, { useEffect, useState } from 'react'
import './PreviousEvents.scss'
import { UtilService } from 'services/shared/UtilService';
import { msgTypes } from 'constants/msgTypes';
import ApiUrl from 'services/shared/ApiUrl';
import { useNavigate } from 'react-router-dom';

const PreviousEvents = () => {
  const [previousEventList, setPreviousEventList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadPreviousEventList()
  }, [])

  const loadPreviousEventList = async () => {
    const res = await UtilService.getPreviousEventList();
    console.log(res);

    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      if (res.data !== '') {
        setPreviousEventList(res.data)
        //setPreviousEventList([])
      } else {
        setPreviousEventList([])
      }
    }
  }

  const viewGallery = (event) => {
    navigate('/post-event-gallery', { state: { event: event } })
  }

  const PreviousEventList = () => {
    return (
      <>
        {previousEventList.map((event) => {
          return (
            <div className='row p-5 events-up'>
              <div className='col-lg-6 d-flex justify-content-center'>
                <div className='event-img'>
                  {(event.image !== '' && event.image !== undefined && event.image !== null) ? (
                    <img src={ApiUrl.fileBaseUrl + event.image} alt=""></img>
                  ) : (
                    <img src="/images/events/no_images.png" alt=""></img>
                  )}


                </div>
              </div>
              <div className='col-lg-6'>
                <div className="events-3-holder h-100">
                  <div className="events-3-description-holder">
                    <h3 >{event.eventName}</h3>
                    <h6 className='p-0 m-0'>Date : &nbsp;&nbsp;{UtilService.displayEventDate(event.eventStartDate, event.eventEndDate)} </h6>
                    <h6 className='p-0 m-0'>Venu : &nbsp;&nbsp;{event.eventVenue}</h6>
                    {/* <h6>{UtilService.displayEventDate(event.eventStartDate, event.eventEndDate)} {event.eventVenue}</h6> */}
                    <div className="faq-one__btn-box contact-btn d-flex">
                      <button type="submit" disabled={event?.gallery?.length == 0} onClick={() => { viewGallery(event) }} className={event?.gallery?.length > 0 ? "thm-btn faq-one__btn" : "thm-btn faq-one__btn disabled-gallery-button"}>View Gallery<span>
                      </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
        }
      </>
    )
  }

  return (
    <>
      {/* <!--Page Header Start--> */}
      <section className="page-header past-event-banner member-section">
        <img src="/images/banner/past-event.png"></img>
        <div className="page-header__bg ">
        </div>
        <div className="container membership-container">
          <div className="page-header__inner membership-head">
            {/* <h2><span className='one--'>1</span>WN <br/>Membership Center</h2> */}
            {/* <div className="thm-breadcrumb__box"> */}
            {/* <ul className="thm-breadcrumb list-unstyled">
                                <li><a href="index.html">Home</a></li>
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Membership Benefits</li>
                            </ul> */}
            {/* </div> */}
          </div>
          {/* <div>
                        <button className='thm-btn main-menu__btn member-ship-btn'><Link to={"/membership-registration"}>Membership Registartion</Link> </button>
                    </div> */}
        </div>
      </section>
      {/* //   <!--Page Header End--> */}


      <div className='container heading-previous-event'>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='upcoming--'>Previous Events</h1>
          </div>
        </div>
      </div>
      <div className='container'>
        {previousEventList.length > 0 ? (
          <PreviousEventList />
        ) : (
          <div className="container">
            <div className="no-gallery mt-0">
              <img src="/images/resources/no-photo-gallery.png" alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PreviousEvents